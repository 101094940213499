import { call, put, takeLatest } from 'redux-saga/effects';

import appActions from '../actions/app';
import errorActions from '../actions/error';
import userActions, { Types as userTypes } from '../actions/user';
import roomActions from '../actions/room';
import organizationActions from '../actions/organization';
import api from '../api/_index';

import { setToken, removeToken } from '../../helpers/auth';
import { getDomain } from '../../helpers/navigation';

const {
  REQUEST_LOGIN_LOCAL,
  REQUEST_LOGIN_CAS,
  REQUEST_LOGOUT,
  REQUEST_USER,
  REQUEST_ORGANIZATIONS,
} = userTypes;

function* authUser(token) {
  try {
    yield call(setToken, token);
    yield put(userActions.setIsAuthenticated(true)); // could use !!getToken() from helpers/auth also...
  } catch (error) {
    yield put(errorActions.handleError(error));
  }
}

function* requestLoginLocal({ email, password }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGIN_LOCAL}`));

    const { token } = yield call(api.user.requestLoginLocal, email, password, getDomain());
    yield call(authUser, token);
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGIN_LOCAL}`));
  }
}

function* requestLoginCas({ ticket, hostname }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGIN_CAS}`));

    const { token } = yield call(api.user.requestLoginCas, ticket, hostname);
    yield call(authUser, token);
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGIN_CAS}`));
  }
}

function* requestLogout({ preLogout, postLogout }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_LOGOUT}`));

    if (preLogout) preLogout();

    yield call(removeToken);

    yield put(roomActions.resetRoom());
    yield put(organizationActions.resetOrganization());
    yield put(userActions.resetUser()); // Always do it in last (reset the isAuthenticated bool to redirect to login)

    if (postLogout) postLogout();
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_LOGOUT}`));
  }
}

function* requestUser() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_USER}`));
    const user = yield call(api.user.requestUser);
    yield put(userActions.setUser(user));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_USER}`));
  }
}

function* requestOrganizations() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ORGANIZATIONS}`));

    const organizations = yield call(api.organization.requestOrganizations);

    yield put(userActions.setOrganizations(organizations));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ORGANIZATIONS}`));
  }
}

function* watcherRequestLoginLocal() {
  yield takeLatest(REQUEST_LOGIN_LOCAL, requestLoginLocal);
}

function* watcherRequestLoginCas() {
  yield takeLatest(REQUEST_LOGIN_CAS, requestLoginCas);
}

function* watcherRequestLogout() {
  yield takeLatest(REQUEST_LOGOUT, requestLogout);
}

function* watcherRequestUser() {
  yield takeLatest(REQUEST_USER, requestUser);
}

function* watcherRequestOrganizations() {
  yield takeLatest(REQUEST_ORGANIZATIONS, requestOrganizations);
}

export default [
  watcherRequestLoginLocal(),
  watcherRequestLoginCas(),
  watcherRequestLogout(),
  watcherRequestUser(),
  watcherRequestOrganizations(),
];
