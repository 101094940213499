import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/user';

import { getToken } from '../../helpers/auth';

/**
 * {
 *  info: {
 *    _id: '',
 *    username: ''
 *  },
 *  organizations: [],
 *  isAuthenticated: bool,
 * }
 */
const initialState = fromJS({
  isAuthenticated: !!getToken(), // Will set Auth state on init, we still need to set in to true on login! (or false on logout)
  organizations: [],
  info: {},
});

export const setUser = (state = initialState, { user }) => state.set('info', fromJS(user));

export const setIsAuthenticated = (state = initialState, { bool }) =>
  state.set('isAuthenticated', bool);

export const setOrganizations = (state = initialState, { organizations }) =>
  state.set('organizations', fromJS(organizations));

// Won't work without the "action" param
export const resetUser = (state = initialState, action) =>
  initialState.set('isAuthenticated', !!getToken());

export default createReducer(initialState, {
  [Types.SET_USER]: setUser,
  [Types.SET_IS_AUTHENTICATED]: setIsAuthenticated,
  [Types.SET_ORGANIZATIONS]: setOrganizations,
  [Types.RESET_USER]: resetUser,
});
