import React, { useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import organizationSelectors from '../../App/selectors/organization';
import organizationActions from '../../App/actions/organization';
import deviceActions from '../../App/actions/device';
import { getDomain } from '../../helpers/navigation';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    borderLeftWidth: 0.5,
    borderLeftColor: colors.getGraySantas(),
    maxHeight: '100vh',
  },
  buttonView: {
    padding: 10,
    borderBottomColor: colors.getMainWhite(),
    borderBottomWidth: '1px',
    borderStyle: 'solid',
  },
  text: {
    color: colors.getMainWhite(),
  },
});

const Devices = ({ devices, organizationId, requestDevices, setDevice }) => {
  useEffect(() => {
    if (organizationId !== undefined) requestDevices();
    // => Saga will also request logged in devices from socket and update redux
  }, [organizationId]);

  const onClick = (device) => {
    setDevice(device);
  };

  const deviceList = () => {
    return devices.map((device) => (
      <TouchableOpacity
        key={device.hardwareID}
        onPress={() => onClick(device)}
        disabled={getDomain().includes('kalyzee') ? !device.online : false}
      >
        <View style={styles.buttonView}>
          <Text style={styles.text}>{device.hardwareID}</Text>
          <Text style={styles.text}>{device.online ? 'online' : 'offline'}</Text>
        </View>
      </TouchableOpacity>
    ));
  };

  return <View style={styles.container}>{deviceList()}</View>;
};

Devices.propTypes = {
  devices: PropTypes.array.isRequired,
  organizationId: PropTypes.string,
  requestDevices: PropTypes.func.isRequired,
  setDevice: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  devices: organizationSelectors.makeSelectDevices(),
  organizationId: organizationSelectors.makeSelectOrganizationId(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestDevices: () => dispatch(organizationActions.requestDevices()),
    setDevice: (device) => dispatch(deviceActions.setDevice(device)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
