import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TextInput, Button } from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import colors from '../../constants/colors';

import userActions, { Types as userTypes } from '../../App/actions/user';
import appSelectors from '../../App/selectors/app';

const { REQUEST_LOGIN_LOCAL } = userTypes;

const styles = StyleSheet.create({
  label: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.getBluePrussian,
  },
  input: {
    marginTop: 10,
    marginBottom: 15,
    height: 60,
    padding: 10,
    borderRadius: 10,
    border: `solid 1px ${colors.getGraySantas(0.14)}`,
    backgroundColor: colors.getMainWhite(),
  },
  error: {
    marginBottom: 15,
    color: colors.getRed(),
  },
  button: {
    borderRadius: 10,
    backdropFilter: 'blur(25px)',
    backgroundColor: colors.getMainPurple(),
    paddingVertical: 22,
    paddingHorizontal: 130,
  },
});

const LoginLocal = ({ currentlySending, requestLoginLocal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);

  const validateEmail = () => {
    if (email.includes('@')) {
      if (errorEmail !== '') setErrorEmail(null);
      return true;
    }
    setErrorEmail('Please enter a valid email');
    return false;
  };

  const validatePassword = () => {
    if (password !== '') {
      if (errorPassword !== '') setErrorPassword(null);
      return true;
    }
    setErrorPassword('Please enter your password');
    return false;
  };

  const validateForm = () => {
    const validatedEmail = validateEmail();
    const validatedPassword = validatePassword();
    return validatedEmail && validatedPassword;
  };

  const handleSubmit = () => {
    if (validateForm()) requestLoginLocal(email, password);
    return null;
  };

  return (
    <>
      <Text style={styles.label}>Email</Text>
      <TextInput placeholder="example@email.fr" style={styles.input} onChangeText={setEmail} />
      {errorEmail !== null && <Text style={styles.error}>{errorEmail}</Text>}
      <Text style={styles.label}>Password</Text>
      <TextInput secureTextEntry={true} style={styles.input} onChangeText={setPassword} />
      {errorPassword !== null && <Text style={styles.error}>{errorPassword}</Text>}

      <Button title="Login" onPress={handleSubmit} disabled={currentlySending} color="#5b41f0" />
    </>
  );
};

LoginLocal.propTypes = {
  currentlySending: PropTypes.bool.isRequired,

  // Actions
  requestLoginLocal: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentlySending: appSelectors.makeSelectCurrentlySending([REQUEST_LOGIN_LOCAL]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestLoginLocal: (email, password) =>
      dispatch(userActions.requestLoginLocal(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLocal);
