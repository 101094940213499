import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { WHITE, PURPLE } from '../../../constants/colors';

import IconComposeReverse from '../../../assets/settings/icon-view-composee-reverse.svg';
import IconCompose from '../../../assets/settings/icon-view-compose.svg';
import IconMoiteMoite from '../../../assets/settings/icon-view-thumbnail.svg';
import IconMosaiqueGauche from '../../../assets/settings/icon-view-mosaic-left.svg';
import IconMosaiqueGDroite from '../../../assets/settings/icon-view-mosaic-right.svg';
import IconSceneSplittedInactive from '../../../assets/settings/icon-scene-splitted-inactive.svg';
import IconSceneSplittedActive from '../../../assets/settings/icon-scene-splitted-active.svg';
import IconSceneCompositeInactive from '../../../assets/settings/icon-scene-composite-inactive.svg';
import IconSceneCompositeActive from '../../../assets/settings/icon-scene-composite-active.svg';

import systemSelectors from '../../../App/selectors/system';
import systemActions from '../../../App/actions/system';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    flexDirection: 'row',
  },
  SettingsAffichageScene: {
    flex: 12,
    backgroundColor: 'white',
    flexDirection: 'row',
    marginLeft: 100,
  },
  left: {
    flex: 11,
    flexDirection: 'column',
  },
  display: {
    flexDirection: 'row',
    paddingVertical: 25,
    borderRadius: 20,
    backgroundColor: '#f8f8f9',
    paddingHorizontal: 1,
  },
  displayCamera: {
    height: 212,
    flex: 6,
    borderRadius: 6,
    backgroundImage: 'linear-gradient(292deg, #c69a7e 97%, #dbc5b7 4%)',
  },
  displayCameraMin: {
    flex: 3,
    height: 165,
    borderRadius: 6,
    backgroundImage: 'linear-gradient(292deg, #c69a7e 97%, #dbc5b7 4%)',
  },
  displayDiapo: {
    height: 212,
    flex: 6,
    backgroundColor: WHITE,
  },
  displayCameraRight: {
    height: 212,
    flex: 6,
    borderRadius: 6,
    backgroundImage: 'linear-gradient(292deg, #c69a7e 97%, #dbc5b7 4%)',
  },
  displayCameraRightMin: {
    height: 165,
    borderRadius: 6,
    backgroundImage: 'linear-gradient(292deg, #c69a7e 97%, #dbc5b7 4%)',
    flex: 3,
  },
  displayDiapoRight: {
    height: 212,
    backgroundColor: WHITE,
    flex: 6,
  },
  selection: {
    marginRight: 221,
    marginBottom: 20,
    paddingHorizontal: 30,
    paddingVertical: 25,
    borderRadius: 20,
    backgroundColor: '#f8f8f9',
    marginTop: 16,
  },
  TextSelection: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.36,
    textAlign: 'left',
    color: '#00245a',
  },
  button: {
    flexDirection: 'row',
  },
  ButtonSelection: {
    width: '30%',
    borderRadius: 16,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 5,
    justifyContent: 'center',
    marginRight: 21,
    marginTop: 16,
  },
  ButtonSelectionSelected: {
    width: '30%',
    borderRadius: 16,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 5,
    justifyContent: 'center',
    marginRight: 21,
    marginTop: 16,
    border: 'solid 1px #6347ff',
  },
  textbutton: {
    marginVertical: 'auto',
    fontSize: 16,
    letterspacing: 0.62,
    color: '#00245a',
  },
  buttonRegister: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    backgroundColor: PURPLE,
    borderRadius: 16,
    marginBottom: 24,
    marginHorizontal: 'auto',
    width: 178,
    height: 50,
  },
  menuscene: {
    marginLeft: 28,
    marginRight: 38,
    width: 50,
    height: 104,
    borderRadius: 16,
    backdropFilter: 'blur(10px)',
    backgroundColor: '#f8f8f9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  IconeSceneActive: {
    marginHorizontal: 'auto',
    backgroundColor: PURPLE,
    borderRadius: 16,
    marginTop: 5,
  },
  IconeScene: {
    marginHorizontal: 'auto',
    marginTop: 5,
  },
});

class SettingAffichageScene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ordreNI: 1,
      ordreVMM: 1,
      Scene: 1,
    };
  }

  getIconSceneSplitted = () => {
    if (this.props.video.scene.Scene === 1) return <IconSceneSplittedActive />;
    return <IconSceneSplittedInactive />;
  };

  getIconSceneComposite = () => {
    if (this.props.video.scene.Scene === 2) return <IconSceneCompositeActive />;
    return <IconSceneCompositeInactive />;
  };

  render() {
    return (
      <View style={styles.SettingsAffichageScene}>
        <View style={styles.left}>
          {this.getOrdre()}
          <View style={styles.selection}>
            <Text style={styles.TextSelection}>Sélectionnez l’affichage de la vue 50/50</Text>
            {this.getButtom()}
          </View>
          <View style={styles.buttonRegister}>
            <Text style={{ fontSize: 16, fontWeight: 600, textAlign: 'center', color: '#ffffff' }}>
              Enregister
            </Text>
          </View>
        </View>
        <View style={styles.menuscene}>
          <TouchableOpacity
            onPress={() =>
              this.props.switchScene({
                Scene: 1,
                ordreNI: this.props.video.scene.ordreNI,
                ordreVMM: this.props.video.scene.ordreVMM,
              })
            }
          >
            {this.getIconSceneSplitted()}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              this.props.switchScene({
                Scene: 2,
                ordreNI: this.props.video.scene.ordreNI,
                ordreVMM: this.props.video.scene.ordreVMM,
              })
            }
          >
            {this.getIconSceneComposite()}
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  getOrdre = () => {
    if (this.props.video.scene.Scene === 1) {
      if (this.props.video.scene.ordreNI === 1) {
        return (
          <View style={styles.display}>
            <View style={styles.displayCamera} />
            <View style={styles.displayDiapoRight} />
          </View>
        );
      }
      if (this.props.video.scene.ordreNI === 2) {
        return (
          <View style={styles.display}>
            <View style={styles.displayDiapo} />
            <View style={styles.displayCameraRight} />
          </View>
        );
      }
    } else if (this.props.video.scene.Scene === 2) {
      if (this.props.video.scene.ordreVMM === 1) {
        return (
          <View style={styles.display}>
            <View style={styles.displayCameraMin} />
            <View style={styles.displayDiapoRight} />
          </View>
        );
      }
      if (this.props.video.scene.ordreVMM === 2) {
        return (
          <View style={styles.display}>
            <View style={styles.displayCameraMin} />
            <View style={styles.displayDiapoRight} />
          </View>
        );
      }
      if (this.props.video.scene.ordreVMM === 3) {
        return (
          <View style={styles.display}>
            <View style={styles.displayDiapo} />
            <View style={styles.displayCameraRightMin} />
          </View>
        );
      }
    }
    return null;
  };

  getButtom = () => {
    if (this.props.video.scene.Scene === 1) {
      return (
        <View style={styles.button}>
          <View
            style={
              this.props.video.scene.ordreNI === 1
                ? styles.ButtonSelectionSelected
                : styles.ButtonSelection
            }
            onClick={() =>
              this.props.switchScene({
                Scene: this.props.video.scene.Scene,
                ordreNI: 1,
                ordreVMM: this.props.video.scene.ordreVMM,
              })
            }
          >
            <IconCompose style={{ marginRight: 23 }} />
            <Text style={styles.textbutton}>Normal</Text>
          </View>
          <View
            style={
              this.props.video.scene.ordreNI === 2
                ? styles.ButtonSelectionSelected
                : styles.ButtonSelection
            }
            onClick={() =>
              this.props.switchScene({
                Scene: this.props.video.scene.Scene,
                ordreNI: 2,
                ordreVMM: this.props.video.scene.ordreVMM,
              })
            }
          >
            <IconComposeReverse style={{ marginRight: 23 }} />
            <Text style={styles.textbutton}>Inversé</Text>
          </View>
        </View>
      );
    }
    if (this.props.video.scene.Scene === 2) {
      return (
        <View style={styles.button}>
          <View
            style={
              this.props.video.scene.ordreVMM === 1
                ? styles.ButtonSelectionSelected
                : styles.ButtonSelection
            }
            onClick={() =>
              this.props.switchScene({
                Scene: this.props.video.scene.Scene,
                ordreNI: this.props.video.scene.ordreNI,
                ordreVMM: 1,
              })
            }
          >
            <IconMoiteMoite style={{ marginRight: 15 }} />
            <Text style={styles.textbutton}>Vignette</Text>
          </View>
          <View
            style={
              this.props.video.scene.ordreVMM === 2
                ? styles.ButtonSelectionSelected
                : styles.ButtonSelection
            }
            onClick={() =>
              this.props.switchScene({
                Scene: this.props.video.scene.Scene,
                ordreNI: this.props.video.scene.ordreNI,
                ordreVMM: 2,
              })
            }
          >
            <IconMosaiqueGauche style={{ marginRight: 15 }} />
            <Text style={styles.textbutton}>
              Mozaïque
              <br />
              gauche
            </Text>
          </View>
          <View
            style={
              this.props.video.scene.ordreVMM === 3
                ? styles.ButtonSelectionSelected
                : styles.ButtonSelection
            }
            onClick={() =>
              this.props.switchScene({
                Scene: this.props.video.scene.Scene,
                ordreNI: this.props.video.scene.ordreNI,
                ordreVMM: 3,
              })
            }
          >
            <IconMosaiqueGDroite style={{ marginRight: 15 }} />
            <Text style={styles.textbutton}>
              Mozaïque
              <br />
              droite
            </Text>
          </View>
        </View>
      );
    }
    return null;
  };
}

SettingAffichageScene.propTypes = {
  video: PropTypes.object.isRequired,

  // Actions
  switchScene: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  video: systemSelectors.makeSelectVideo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    switchScene: (object) => dispatch(systemActions.switchScene(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingAffichageScene);
