import { put, call, fork, take, cancel } from 'redux-saga/effects';

import { Types as errorTypes } from '../actions/error';
import userActions from '../actions/user';

function* unauthorizedError(message) {
  let error = message;
  if (error === undefined) {
    error = 'Unauthorized';
  }
  yield put(userActions.requestLogout());
  yield alert(error);
}

function* notFoundError(message) {
  let error = message;
  if (error === undefined) {
    error = 'Not found';
  }
  yield alert(error);
}

function* internalError(message) {
  let error = message;
  if (error === undefined) {
    error = 'Internal server error';
  }
  yield alert(error);
}

function* defaultError(message) {
  yield alert(message);
}

function* handleError({ error }) {
  const { response } = error;
  const { status } = response || {};

  const body = yield response.json();

  const { message } = body;

  switch (status) {
    case 401:
      yield call(unauthorizedError, message);
      break;
    case 404:
      yield call(notFoundError, message);
      break;
    case 500:
      yield call(internalError, message);
      break;

    default:
      yield call(defaultError, message);
  }

  /**
   * Need to do a "<WithAlert />" and use it along side <WithSocket />
   * Create a React Ref with function "alert(title, message, duration)".
   * Update the WithAlert Containers from the ref..
   * Could also create a hook useAlert(time, message, duration) => update the ref...
   * position absolute, visible for the duration time (hidden otherwise)
   *
   * yield alert(message);
   * */
}

/**
 * Idea : If a 401 error is catched, the other errors that are pending are all cancelled.
 * -> An error is pending if it was catched but didn't reach the point where it is displayed / logged yet.
 *
 * The following function is useful when a single action on the interface can simultaneously fire many requests to the backend,
 * which can potentially all fail if the user token is broken, causing the interface to display many pop-ups.
 *
 * Idea of improvement :
 * 1) Do not cancel totally the pending requests, only the pop-ups.
 * 2) Save every error in a stack.
 * 3) Make so that the most recent error, the one that managed to reach the end of the error handling,
 *    displays the error stack, so no information is 'lost' even for the user. (Could be a pop-up or a dedicated page)
 * 4) This could be generalized to every kinds of errors.
 */
function* watcherHandleError() {
  let pendingErrors = [];

  while (true) {
    const errorAction = yield take(errorTypes.HANDLE_ERROR);

    if (errorAction.error.response && errorAction.error.response.status === 401) {
      yield cancel(pendingErrors);
      pendingErrors = [];
    }

    const errorTask = yield fork(handleError, errorAction);
    pendingErrors.push(errorTask);
  }
}

export default [watcherHandleError()];
