import { USER_ROLE_TO_INT } from '../constants/global';

// GT => Greater Than
// GTE => Greater Than or Equal
// LT => Lesser Than
// LTE => Lesser Than or Equal

export const userRoleIsGT = (userRole, target) => {
  // Importance/index is admin=0, technician=1, professor=2, student=3, ...
  // Keep in mind that role are "reversed" for the comparison
  // So, tech > prof (logic) is in fact tech < prof (index)
  return USER_ROLE_TO_INT[userRole] < USER_ROLE_TO_INT[target];
};

export const userRoleIsGTE = (userRole, target) => {
  // Importance/index is admin=0, technician=1, professor=2, student=3, ...
  // Keep in mind that role are "reversed" for the comparison
  // So, tech >= prof (logic) is in fact tech <= prof (index)
  return USER_ROLE_TO_INT[userRole] <= USER_ROLE_TO_INT[target];
};

export const userRoleIsEqualTo = (userRole, target) => {
  return USER_ROLE_TO_INT[userRole] === USER_ROLE_TO_INT[target];
};

export const userRoleIsLT = (userRole, target) => {
  // Importance/index is admin=0, technician=1, professor=2, student=3, ...
  // Keep in mind that role are "reversed" for the comparison
  // So, tech <= prof (logic) is in fact tech >= prof (index)
  return USER_ROLE_TO_INT[userRole] >= USER_ROLE_TO_INT[target];
};

export const userRoleIsLTE = (userRole, target) => {
  // Importance/index is admin=0, technician=1, professor=2, student=3, ...
  // Keep in mind that role are "reversed" for the comparison
  // So, tech < prof (logic) is in fact tech > prof (index)
  return USER_ROLE_TO_INT[userRole] > USER_ROLE_TO_INT[target];
};

export default {
  userRoleIsGT,
  userRoleIsGTE,
  userRoleIsEqualTo,
  userRoleIsLT,
  userRoleIsLTE,
};
