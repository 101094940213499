import { StyleSheet, View, TextInput } from 'react-native';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment';

import colors from '../../constants/colors';

import roomActions from '../../App/actions/room';
import userSelectors from '../../App/selectors/user';

import SendButton from '../../assets/blue/icon-send-message.svg';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    minHeight: 56, // fix height (10 padding bottom + 46 by default for the input)
    maxHeight: 56, // fix height (10 padding bottom + 46 by default for the input)
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.getClayEbony(0.75),
    borderRadius: 10,
    padding: 13,
  },
  inputText: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    height: '100%',
    width: '85%',
    color: 'white',
    marginRight: 20,
    outlineWidth: 0,
  },
  inputSubmit: {
    minHeight: 20, // fix icon size
    maxHeight: 20, // fix icon size
    minWidth: 20, // fix icon size
    maxWidth: 20, // fix icon size
    marginLeft: 5,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});

const Footer = (props) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    const inputValueCopy = inputValue;
    setInputValue('');

    if (inputValueCopy.trim() === '') return;

    const message = {
      content: inputValueCopy,
      datetime: moment().format(),
      authorUsername: props.user.username,
      authorId: props.user._id,
      authorAvatar: props.user.avatar,
    };
    props.setOwnMessage(message);
  };

  const chatInput = () => {
    return (
      <View style={styles.inputContainer}>
        <TextInput
          type="text"
          placeholder="Envoyer un message"
          value={inputValue}
          onChange={handleChange}
          style={styles.inputText}
          onSubmitEditing={handleSubmit} // Allow "Enter" key to submit the message
        />
        <SendButton onClick={handleSubmit} style={styles.inputSubmit} />
      </View>
    );
  };

  return <View style={styles.container}>{chatInput()}</View>;
};

Footer.propTypes = {
  user: PropTypes.object.isRequired,

  // Actions
  setOwnMessage: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: userSelectors.makeSelectUserInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOwnMessage: (message) => dispatch(roomActions.setOwnMessage(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
