import { createSelector } from 'reselect';

const deviceSelector = (globalState) => globalState.get('device');
const deviceInfoSelector = (state) => deviceSelector(state).get('info');
const deviceStatusSelector = (state) => deviceSelector(state).get('status');

const makeSelectDeviceInfo = () => createSelector(deviceInfoSelector, (state) => state.toJS());

const makeSelectDeviceStatus = () => createSelector(deviceStatusSelector, (state) => state.toJS());

const makeSelectDeviceOnlineStatus = () =>
  createSelector(deviceStatusSelector, (state) => state.get('online'));

const makeSelectDeviceLiveStartedAt = () =>
  createSelector(deviceStatusSelector, (state) => state.get('liveStartedAt'));

export default {
  makeSelectDeviceInfo,
  makeSelectDeviceStatus,
  makeSelectDeviceOnlineStatus,
  makeSelectDeviceLiveStartedAt,
};
