import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';
import organizationActions, { Types as organizationTypes } from '../actions/organization';
import organizationSelectors from '../selectors/organization';
import userSelectors from '../selectors/user';
import { getDomain } from '../../helpers/navigation';

const {
  REQUEST_AUTH_INFO,
  REQUEST_ORGANIZATION,
  REQUEST_ROOMS,
  REQUEST_DEVICES,
} = organizationTypes;

function* requestAuthInfo() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_AUTH_INFO}`));

    const authInfo = yield call(api.organization.requestAuthInfo, getDomain());

    yield put(organizationActions.setAuthInfo(authInfo));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_AUTH_INFO}`));
  }
}

function* requestOrganization() {
  const user = yield select(userSelectors.makeSelectUserInfo());

  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ORGANIZATION}`));

    const organization = yield call(api.organization.requestOrganization, user._organization);

    yield put(organizationActions.setOrganization(organization));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ORGANIZATION}`));
  }
}

function* requestRooms() {
  const organizationId = yield select(organizationSelectors.makeSelectOrganizationId());

  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ROOMS}`));

    const rooms = yield call(api.organization.requestRooms, organizationId);

    yield put(organizationActions.setRooms(rooms));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ROOMS}`));
  }
}

function* requestDevices() {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_DEVICES}`));

    const devices = yield call(api.device.requestDevices);

    yield put(organizationActions.setDevices(devices));

    yield put(appActions.socketRequestDevices()); // Will trigger event for socket.io to get online devices
  } catch (error) {
    yield put(appActions.alertError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_DEVICES}`));
  }
}

function* watcherRequestAuthInfo() {
  yield takeLatest(REQUEST_AUTH_INFO, requestAuthInfo);
}

function* watcherRequestOrganization() {
  yield takeLatest(REQUEST_ORGANIZATION, requestOrganization);
}

function* watcherRequestRooms() {
  yield takeLatest(REQUEST_ROOMS, requestRooms);
}

function* watcherRequestDevices() {
  yield takeLatest(REQUEST_DEVICES, requestDevices);
}

export default [
  watcherRequestAuthInfo(),
  watcherRequestOrganization(),
  watcherRequestRooms(),
  watcherRequestDevices(),
];
