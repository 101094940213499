import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { sCameraControlChevron } from '../../styles/fixed';
import Chevron from '../../assets/navigation/chevron.svg';
import colors from '../../constants/colors';

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    zIndex: 1000,
  },
  chevronRow: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  chevronButton: {
    backgroundColor: colors.getMainWhite(0.4),
    borderRadius: '50%',
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const chevronSize = sCameraControlChevron.height;

const CameraControl = ({ move, stopMove, display, isIframe }) => {
  const [extraStyle, setExtraStyle] = useState({ display: 'none' });

  useEffect(() => {
    if (isIframe) {
      const iframe = window.document.querySelector('iframe');
      if (iframe !== null && iframe.offsetWidth && iframe.offsetHeight) {
        const height = iframe.offsetHeight;
        const width = iframe.offsetWidth;

        setExtraStyle({ width, height });
      }
    } else {
      const video = window.document.querySelector('video');
      if (video !== null && video.offsetWidth && video.offsetHeight) {
        let height = 'unset';
        let width = 'unset';
        const { offsetWidth, videoHeight, videoWidth } = video;

        height = (videoHeight / videoWidth) * offsetWidth;
        width = (videoWidth / videoHeight) * height;

        setExtraStyle({ width, height });
      }
    }
  }, []);

  return display ? (
    <View style={[styles.container, extraStyle]}>
      <View style={[styles.chevronRow, { justifyContent: 'center' }]}>
        <TouchableOpacity onPressIn={() => move('up')} onPressOut={() => stopMove()}>
          <View style={[styles.chevronButton, { paddingBottom: 3 }]}>
            <Chevron height={chevronSize} width={chevronSize} fill={colors.getBluePrussian()} />
          </View>
        </TouchableOpacity>
      </View>
      <View style={[styles.chevronRow, { justifyContent: 'space-between' }]}>
        <TouchableOpacity onPressIn={() => move('left')} onPressOut={() => stopMove()}>
          <View style={[styles.chevronButton, { paddingRight: 3 }]}>
            <Chevron
              height={chevronSize}
              width={chevronSize}
              fill={colors.getBluePrussian()}
              rotation={270}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPressIn={() => move('right')} onPressOut={() => stopMove()}>
          <View style={[styles.chevronButton, { paddingLeft: 3 }]}>
            <Chevron
              height={chevronSize}
              width={chevronSize}
              fill={colors.getBluePrussian()}
              rotation={90}
            />
          </View>
        </TouchableOpacity>
      </View>
      <View style={[styles.chevronRow, { justifyContent: 'center' }]}>
        <TouchableOpacity onPressIn={() => move('down')} onPressOut={() => stopMove()}>
          <View style={[styles.chevronButton, { paddingTop: 3 }]}>
            <Chevron
              height={chevronSize}
              width={chevronSize}
              fill={colors.getBluePrussian()}
              rotation={180}
            />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  ) : null;
};

CameraControl.propTypes = {
  display: PropTypes.bool,
  move: PropTypes.func.isRequired,
  stopMove: PropTypes.func.isRequired,
  isIframe: PropTypes.bool,
};

export default CameraControl;
