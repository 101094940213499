import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestLoginLocal: ['email', 'password'],
  requestLoginCas: ['ticket', 'hostname'],
  requestLogout: ['preLogout', 'postLogout'], // postLogout is a callback, preLogout is the inverse (first called)

  requestUser: [],

  requestOrganizations: [],

  // Set reducers
  setUser: ['user'],
  setIsAuthenticated: ['bool'],
  setOrganizations: ['organizations'],

  resetUser: [],
});

export { Types };
export default Creators;
