import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { createStructuredSelector } from 'reselect';

import colors from '../../constants/colors';
import { screenName } from '../../constants/navigation';
import { isAdminDomain } from '../../helpers/navigation';
import roomActions from '../../App/actions/room';
import organizationActions from '../../App/actions/organization';
import organizationSelectors from '../../App/selectors/organization';
import appSelectors from '../../App/selectors/app';
import IconPorteOuverte from '../../assets/settings/icon-porte-ouverte.svg';

const styles = StyleSheet.create({
  textTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    marginBottom: 40,
  },
  textTitleFirst: {
    fontSize: 29,
    fontWeight: 900,
    color: colors.getBluePrussian(),
    marginRight: 11,
  },
  textTitleSecond: {
    fontSize: 24,
    fontWeight: 900,
    letterSpacing: '-0.5px',
    color: colors.getMainGreen(),
    marginLeft: 11,
  },
});

const RoomPage = (props) => {
  useEffect(() => {
    if (isAdminDomain() && props.organization._id === undefined)
      props.navigation.navigate(screenName.ORGANIZATIONS);
    else if (props.organization._id !== undefined) props.requestRooms();
  }, []);

  useEffect(() => {
    if (props.organization._id !== undefined && props.rooms.length === 0) props.requestRooms();
  }, [props.organization._id]);

  const handleSelectRoom = (room) => {
    props.setRoom(room);
    props.navigation.navigate(screenName.HOME);
    props.requestRoomDevices(room._id);
  };

  const getRooms = () => {
    return props.rooms.map((room) => (
      <TouchableOpacity
        key={room._id}
        onPress={() => handleSelectRoom(room)}
        style={{
          width: 190,
          height: 208,
          marginRight: 20,
          marginLeft: 20,
          marginBottom: 20,
          marginTop: 20,
          borderRadius: 20,
          border: `solid 1px ${colors.getGraySantas(0.18)}`,
          backgroundColor: colors.getGrayAthens(),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <IconPorteOuverte style={{ marginBottom: 17 }} />
        <Text>{room.name}</Text>
      </TouchableOpacity>
    ));
  };

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: colors.getMainWhite(),
        padding: 40,
      }}
    >
      <View style={styles.textTitle}>
        <Text style={styles.textTitleSecond}>Sélectionner une salle</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          overflow: 'auto',
        }}
      >
        {props.currentlySending ? <Text>Chargement ...</Text> : getRooms()}
      </View>
    </View>
  );
};

RoomPage.propTypes = {
  navigation: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
  currentlySending: PropTypes.bool.isRequired,
  // Actions
  requestRooms: PropTypes.func.isRequired,
  setRoom: PropTypes.func.isRequired,
  requestRoomDevices: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  rooms: organizationSelectors.makeSelectRooms(),
  organization: organizationSelectors.makeSelectOrganization(),
  currentlySending: appSelectors.makeSelectCurrentlySending(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestRooms: () => dispatch(organizationActions.requestRooms()),
    setRoom: (room) => dispatch(roomActions.setRoom(room)),
    requestRoomDevices: (_room) => dispatch(roomActions.requestRoomDevices(_room)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomPage);
