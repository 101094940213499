import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { isReadyRef, navigationRef, isAdminDomain } from '../../helpers/navigation';
import { screen } from '../../constants/navigation';

import AuthAwareStack from '../AuthAwareStack';

import userSelectors from '../../App/selectors/user';
import userActions from '../../App/actions/user';
import organizationActions from '../../App/actions/organization';

const prefixes = [Linking.createURL('/')];

const AuthAwareNavigation = ({ isAuthenticated, user, requestUser, requestOrganization }) => {
  const [isAdmin] = useState(isAdminDomain());

  useEffect(() => {
    return () => {
      isReadyRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) requestUser();
  }, [isAuthenticated]);

  useEffect(() => {
    // if there is a hostname (eg: univ-amu (from univ-amu.kast.app)) we request the info of the organization
    // otherwise it means it's an admin process (extra step to select the organization)
    if (user?._organization !== undefined && !isAdmin) requestOrganization();
  }, [user]);

  const onReady = () => {
    isReadyRef.current = true;
  };

  const config = { screens: screen };
  const linking = { prefixes, config };

  return (
    <NavigationContainer ref={navigationRef} onReady={onReady} linking={linking}>
      <AuthAwareStack />
    </NavigationContainer>
  );
};

AuthAwareNavigation.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,

  // Actions
  requestUser: PropTypes.func.isRequired,
  requestOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
  user: userSelectors.makeSelectUserInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestUser: () => dispatch(userActions.requestUser()),
    requestOrganization: () => dispatch(organizationActions.requestOrganization()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthAwareNavigation);
