import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { StyleSheet, View, Text, Button } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import moment from 'moment';

import activitySelectors from '../../App/selectors/activity';
import activityActions from '../../App/actions/activity';
import CustomDatepicker from './customDatepicker';

const styles = StyleSheet.create({
  formRow: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  formLabel: {
    width: '25%',
  },
  selectContainer: {
    borderRadius: '0.5em',
    backgroundColor: 'white',
    height: '2.75em',
    borderColor: '#ccc',
    borderWidth: 1,
    paddingRight: '0.75em',
    paddingLeft: '0.75em',
    display: 'flex',
    alignItems: 'center',
  },
  picker: {
    borderColor: 'unset',
    borderWidth: 0,
    backgroundColor: 'white',
    fontSize: 16,
    height: '100%',
  },
});

const SchedulePage = ({
  route,
  availableRooms,
  requestAvailableRooms,
  requestScheduleActivity,
}) => {
  const roundedUp = Math.ceil(moment().minute() / 15) * 15;
  const now = moment().minute(roundedUp).second(0).millisecond(0);

  const [datetimes, setDatetime] = useState([now.clone(), null]);
  const [disableSave, setDisableSave] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState(undefined);
  const [externalId, setExternalId] = useState(undefined);

  useEffect(() => {
    if (datetimes.every((el) => el !== null))
      requestAvailableRooms(datetimes.map((el) => el.toISOString()));
    if (route?.params?.external_id) setExternalId(route.params.external_id);
  }, []);

  useEffect(() => {
    setSelectedRoom(availableRooms[0]?._id);
  }, [availableRooms]);

  useEffect(() => {
    if (datetimes.some((el) => el !== null) && selectedRoom !== undefined) setDisableSave(false);
  }, [datetimes, selectedRoom]);

  const onDatePickerClose = () => {
    if (datetimes.every((el) => el !== null))
      requestAvailableRooms(datetimes.map((el) => el.toISOString()));
  };

  const onRoomPickerChange = (itemValue, itemIndex) => {
    setSelectedRoom(itemValue);
  };

  const onSave = () => {
    requestScheduleActivity(
      datetimes.map((el) => el.toISOString()),
      selectedRoom,
      externalId,
    );
  };

  const getPickerItems = () => {
    return availableRooms.length > 0 ? (
      availableRooms.map((room) => (
        <Picker.Item key={room._id} label={room.name} value={room._id} />
      ))
    ) : (
      <Picker.Item key="room-placeholder" label="Sélectionner un créneau horaire" />
    );
  };

  return (
    <View>
      <View style={styles.formRow}>
        <Text style={styles.formLabel}>
          Veuillez sélectionner la plage horaire de votre activité
        </Text>
        <CustomDatepicker
          now={now}
          datetimes={datetimes}
          setDatetime={setDatetime}
          onClose={onDatePickerClose}
        />
      </View>
      <View style={styles.formRow}>
        <Text style={styles.formLabel}>Sélectionner votre salle</Text>
        <View style={styles.selectContainer}>
          <Picker
            selectedValue={selectedRoom}
            onValueChange={onRoomPickerChange}
            style={styles.picker}
            enabled={availableRooms.length > 0}
          >
            {getPickerItems()}
          </Picker>
        </View>
      </View>
      <View style={styles.formRow}>
        <Button title="Sauvegarder" onPress={onSave} disabled={disableSave} />
      </View>
    </View>
  );
};

SchedulePage.propTypes = {
  route: PropTypes.object.isRequired,
  availableRooms: PropTypes.array.isRequired,

  // Action
  requestAvailableRooms: PropTypes.func.isRequired,
  requestScheduleActivity: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  availableRooms: activitySelectors.makeSelectAvailableRooms(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestAvailableRooms: (datetimes) =>
      dispatch(activityActions.requestAvailableRooms(datetimes)),
    requestScheduleActivity: (datetimes, _room, externalId) =>
      dispatch(activityActions.requestScheduleActivity(datetimes, _room, externalId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage);
