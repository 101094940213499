import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import colors, { PURPLE } from '../../../constants/colors';

import IconFullHd from '../../../assets/settings/icon-hd-full.svg';
import IconHd from '../../../assets/settings/icon-hd.svg';

import systemSelectors from '../../../App/selectors/system';
import systemActions from '../../../App/actions/system';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    flexDirection: 'row',
  },
  SettingsAffichageResolution: {
    flex: 12,
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  container: {
    flex: 11,
    paddingVertical: 29,
    paddingHorizontal: 35,
    borderRadius: 20,
    backgroundColor: colors.getMainWhite(),
  },
  TextContainer: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.36,
    color: '#00245a',
  },
  ResolutionButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  square: {
    width: 170,
    height: 170,
    marginTop: 43,
    marginBottom: 4,
    paddingTop: 36,
    borderRadius: 16,
    backgroundColor: colors.getMainWhite(),
  },
  squareSelected: {
    border: 'solid 1.6px #6347ff',
    width: 170,
    height: 170,
    marginTop: 43,
    marginBottom: 4,
    paddingTop: 36,
    borderRadius: 16,
    backgroundColor: colors.getMainWhite(),
  },
  Icon: {
    marginHorizontal: 'auto',
  },
  textsquare: {
    fontSize: 16,
    letterSpacing: 0.62,
    color: '#00245a',
    marginTop: 21,
    marginHorizontal: 'auto',
  },
  reco: {
    opacity: 0.45,
    fontSize: 13,
    letterSpacing: 0.23,
    textAlign: 'center',
    color: '#00245a',
    marginRight: 25,
  },
  enregistrer: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    backgroundColor: PURPLE,
    borderRadius: 16,
    marginBottom: 24,
    marginHorizontal: 'auto',
    width: 178,
    height: 50,
  },
});

class SettingsAffichageResolution extends React.Component {
  render() {
    return (
      <View style={styles.SettingsAffichageResolution}>
        <View style={styles.container}>
          <Text style={styles.TextContainer}>
            Sélectionnez la résolution de la vidéo pour l’export
          </Text>
          <View style={styles.ResolutionButton}>
            <View>
              <View
                style={this.props.video.resolution === 'hd' ? styles.squareSelected : styles.square}
                onClick={() => this.props.switchResolution('hd')}
              >
                <IconHd style={styles.Icon} />
                <Text style={styles.textsquare}>1280 X 720</Text>
              </View>
              <Text style={styles.reco}>
                Recommandé pour
                <br />
                le streaming
              </Text>
            </View>

            <View
              style={
                this.props.video.resolution === 'fullhd' ? styles.squareSelected : styles.square
              }
              onClick={() => this.props.switchResolution('fullhd')}
            >
              <IconFullHd style={styles.Icon} />
              <Text style={styles.textsquare}>1280 X 1080</Text>
            </View>
          </View>
        </View>
        <View style={styles.enregistrer}>
          <Text style={{ fontSize: 16, fontWeight: 600, textAlign: 'center', color: '#ffffff' }}>
            Enregister
          </Text>
        </View>
      </View>
    );
  }
}

SettingsAffichageResolution.propTypes = {
  video: PropTypes.object.isRequired,

  // Actions
  switchResolution: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  video: systemSelectors.makeSelectVideo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    switchResolution: (string) => dispatch(systemActions.switchResolution(string)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAffichageResolution);
