import { createSelector } from 'reselect';

const userSelector = (globalState) => globalState.get('user');
const isAuthenticatedSelector = (state) => userSelector(state).get('isAuthenticated');
const organizationsSelector = (state) => userSelector(state).get('organizations');
const infoSelector = (state) => userSelector(state).get('info');
// User's info
const userAuthType = (state) => infoSelector(state).get('_type');
const usernameSelector = (state) => infoSelector(state).get('username');

// Selectors
const makeSelectUserInfo = () => createSelector(infoSelector, (state) => state.toJS());

const makeSelectIsAuthenticated = () => createSelector(isAuthenticatedSelector, (state) => state);

const makeSelectOrganizations = () =>
  createSelector(organizationsSelector, (state) => state.toJS());

const makeSelectUserAuthType = () => createSelector(userAuthType, (state) => state);

const makeSelectUsername = () => createSelector(usernameSelector, (state) => state);

// const makeSelectIsAdmin = () => createSelector(isAdminSelector, (state) => state);

export default {
  makeSelectUserInfo,
  makeSelectUsername,
  // makeSelectIsAdmin,
  makeSelectIsAuthenticated,
  makeSelectOrganizations,
  makeSelectUserAuthType,
};
