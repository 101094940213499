import { StyleSheet, Text, View } from 'react-native';
import Slider from '@react-native-community/slider';
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import colors, { PURPLE, GREY } from '../../../constants/colors';

import FocusLess from '../../../assets/settings/icon-focus-less.svg';
import FocusMore from '../../../assets/settings/icon-focus-more.svg';

import systemSelectors from '../../../App/selectors/system';
import systemActions from '../../../App/actions/system';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    flexDirection: 'row',
  },
  SettingsAffichagePlan: {
    flex: 12,
    backgroundColor: 'white',
    flexDirection: 'columnxs',
  },
  left: {
    flex: 10,
    flexDirection: 'colunm',
  },
  cameraView: {
    flex: 8,
    borderRadius: 8,
    backgroundImage: `linear-gradient(291deg, ${colors.getRoseOld()} 98%, ${colors.getClamShell()} 3%)`,
  },
  focusView: {
    flex: 2,
    marginTop: 13,
    flexDirection: 'row',
  },
  RightMenu: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    // marginBottom: 280,
  },
  switch: {
    width: 194,
    height: 57,
    flexDirection: 'row',
    paddingTop: 18,
    paddingRigth: 23,
    paddingBottom: 16,
    paddingLeft: 26,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    marginRight: 11,
  },
  Zoom: {
    position: 'absolute',
    right: 0,
    height: 57,
    paddingVertical: 11,
    paddingHorizontal: 16,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    flexDirection: 'row',
  },
  enregistrer: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    backgroundColor: PURPLE,
    borderRadius: 16,
    marginBottom: 24,
    marginHorizontal: 'auto',
    width: 178,
    height: 50,
  },
  MoreLess: {
    position: 'absolute',
    top: 17,
    left: 17,
    height: 57,
    flexDirection: 'row',
    paddingVertical: 11,
    paddingHorizontal: 16,
    borderRadius: 20,
    backgroundColor: colors.getClayEbony(0.25),
  },
  Pipette: {
    flexDirection: 'row',
    paddingVertical: 14,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    marginBottom: 18,
  },
  circle: {
    width: 35,
    height: 35,
    backgroundColor: colors.getBlueDodger(),
    borderRadius: '50%',
    marginRight: 11,
  },

  slider: {
    position: 'absolute',
    left: 47,
    bottom: 64,

    backgroundColor: colors.getClayEbony(0.25),

    backdropFilter: 'blur(10px)',

    paddingTop: 15.5,
    paddingHorizontal: 10,
    paddingBottom: 9.5,
    borderRadius: 20,
  },

  textSlide: {
    marginRight: 5,
    fontSize: 11,
    letterSpacing: 0.15,
    textAlign: 'center',
    color: colors.getMainWhite(),
  },
  helps: {
    marginTop: 25,
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
  },
  TextAide: {
    fontSize: 13,
    letterSpacing: 0.18,
    textAlign: 'center',
    color: colors.getMainPurple(),
    marginLeft: 11,
    marginVertical: 'auto',
  },
});

class SettingAffichageFondVert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AlphaAngle: this.props.video.Angle,
      AlphaBW: this.props.video.BW,
    };
  }

  render() {
    return (
      <View style={styles.SettingsAffichagePlan}>
        <View style={styles.cameraView}></View>

        <View style={styles.MoreLess}>
          <FocusLess style={{ marginRight: 16 }} />
          <FocusMore />
        </View>

        <View style={styles.slider}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Text style={styles.textSlide}>
              Alpha<br></br>angle
            </Text>
            <Slider
              vertical={true}
              style={{ width: 200, height: 40 }}
              minimumValue={0}
              maximumValue={100}
              minimumTrackTintColor={PURPLE}
              maximumTrackTintColor={GREY}
              step={1}
              value={this.props.video.alpha.Angle}
              onValueChange={(val) => this.setState({ AlphaAngle: val })}
            />
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Text style={styles.textSlide}>
              Alpha<br></br>B/W
            </Text>
            <Slider
              vertical={true}
              style={{ width: 200, height: 40 }}
              minimumValue={0}
              maximumValue={100}
              minimumTrackTintColor={PURPLE}
              maximumTrackTintColor={GREY}
              step={1}
              value={this.props.video.alpha.BW}
              onValueChange={(val) => this.setState({ AlphaBW: val })}
            />
          </View>
        </View>
      </View>
    );
  }
}

SettingAffichageFondVert.propTypes = {
  video: PropTypes.object.isRequired,

  // Actions
  switchFocus: PropTypes.func.isRequired,
  sendAlpha: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  video: systemSelectors.makeSelectVideo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    switchFocus: (boolean) => dispatch(systemActions.switchFocus(boolean)),
    sendAlpha: (object) => dispatch(systemActions.sendAlpha(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingAffichageFondVert);
