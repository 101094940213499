import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  addCurrentlySending: ['name'],
  removeCurrentlySending: ['name'],
  socketConnect: [],
  socketDisconnect: [],
  socketRequestDevices: [],
});

export { Types };
export default Creators;
