export const sGlobal = {
  padding: 15,
};

export const sNavBarTop = {
  height: 80,
};

export const sCameraControlChevron = {
  height: 30,
};

export const sVideoControlRow = {
  height: 100,
};

export default {
  sGlobal,
  sNavBarTop,
  sCameraControlChevron,
  sVideoControlRow,
};
