/* eslint-disable no-console */

import { Platform } from 'react-native';
import { WEB_PLATFORM } from '../constants/global';

const storageSetter = (key, value) => {
  if (Platform.OS === WEB_PLATFORM) localStorage.setItem(key, value);
  else console.error('No support for this platform');
};

// eslint-disable-next-line consistent-return
const storageGetter = (key) => {
  if (Platform.OS === WEB_PLATFORM) return localStorage.getItem(key);
  console.error('No support for this platform');
};

const storageRemover = (key) => {
  if (Platform.OS === WEB_PLATFORM) localStorage.removeItem(key);
  else console.error('No support for this platform');
};

// eslint-disable-next-line consistent-return
const getDomain = () => {
  if (Platform.OS === WEB_PLATFORM) return window.location.hostname;
  console.error('No support for this platform');
};

// eslint-disable-next-line consistent-return
const getOrigin = () => {
  if (Platform.OS === WEB_PLATFORM) return window.location.origin;
  console.error('No support for this platform');
  alert('No support for this platform');
};

export default {
  storageSetter,
  storageGetter,
  storageRemover,
  getDomain,
  getOrigin,
};
