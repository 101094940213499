import { StyleSheet, Text, View, Switch } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import colors, { PURPLE, WHITE, GREY } from '../../../constants/colors';

import ZoomPlus from '../../../assets/settings/icon-zoom-plus.svg';
import ZoomLess from '../../../assets/settings/icon-zoom-less.svg';

import MenuPlan from '../menu/MenuPlan';

import systemSelectors from '../../../App/selectors/system';
import systemActions from '../../../App/actions/system';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    flexDirection: 'row',
  },
  SettingsAffichagePlan: {
    with: '100%',
    height: '100%',
  },
  cameraView: {
    height: '100%',
    width: '100%',
    borderRadius: 8,
    backgroundImage: 'linear-gradient(291deg, #c69a7e 98%, #dbc5b7 3%)',
  },
  focusView: {
    flexDirection: 'row',
    position: 'absolute',
    left: 47,
    bottom: 48,
  },
  planView: {
    height: 150,
    position: 'absolute',
    right: 13,
    top: 14,
  },
  switch: {
    width: 194,
    height: 57,
    flexDirection: 'row',
    paddingTop: 18,
    paddingRigth: 23,
    paddingBottom: 16,
    paddingLeft: 26,
    borderRadius: 20,
    backgroundColor: colors.getClayEbony(0.25),
  },
  Zoom: {
    position: 'absolute',
    height: 57,
    paddingVertical: 11,
    paddingHorizontal: 16,
    marginTop: 17,
    marginLeft: 18,
    borderRadius: 20,
    backgroundColor: colors.getClayEbony(0.25),
    backdropFilter: 'blur(10px)',
    flexDirection: 'row',
  },
  enregistrer: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    backgroundColor: PURPLE,
    borderRadius: 16,
    marginBottom: 24,
    marginHorizontal: 'auto',
    width: 178,
    height: 50,
  },
});

class SettingAffichagePlanMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <View style={styles.SettingsAffichagePlan}>
        <View style={styles.cameraView}></View>

        <View style={styles.Zoom}>
          <ZoomLess style={{ marginRight: 13 }} />
          <ZoomPlus />
        </View>

        <View style={styles.planView}>
          <MenuPlan />
        </View>

        <View style={styles.focusView}>
          <View style={styles.switch}>
            <Text style={{ fontSize: 16, color: colors.getMainWhite(), marginRight: 28 }}>
              Focus auto
            </Text>
            <Switch
              trackColor={{ false: GREY, true: PURPLE }}
              thumbColor={this.state.FocusValue ? WHITE : WHITE}
              ios_backgroundColor="#fefefe"
              onValueChange={() => {
                this.props.switchFocus(!this.props.video.focus);
              }}
              value={this.props.video.focus}
            />
          </View>
        </View>
        <View style={styles.enregistrer}>
          <Text style={{ fontSize: 16, fontWeight: 600, textAlign: 'center', color: '#ffffff' }}>
            Enregistrer
          </Text>
        </View>
      </View>
    );
  }
}

SettingAffichagePlanMobile.propTypes = {
  video: PropTypes.object.isRequired,

  // Actions
  switchFocus: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  video: systemSelectors.makeSelectVideo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    switchFocus: (boolean) => dispatch(systemActions.switchFocus(boolean)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingAffichagePlanMobile);
