import organization from './organization';
import user from './user';
import activity from './activity';
import device from './device';

const api = {
  organization,
  user,
  activity,
  device,
};

export default api;
