import { requestAPI } from '../../helpers/request';
import { getAuthHeader } from '../../helpers/auth';

const basePath = '/activity';

const requestScheduleActivity = (body) => {
  const path = `${basePath}/schedule`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
    body: JSON.stringify(body),
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestAvailableRooms = (params) => {
  const path = `${basePath}/available-rooms`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(
    path,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader,
      },
    },
    params,
  )
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestActivity = (_id) => {
  const path = `${basePath}/${_id}`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

export default {
  requestScheduleActivity,
  requestAvailableRooms,
  requestActivity,
};
