import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import { View, Button, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import deviceActions from '../../../App/actions/device';

const styles = StyleSheet.create({
  settingContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
  },
  settingText: {
    color: 'white',
  },
});

const SettingDevice = ({ device, rooms, requestUpdateDevice }) => {
  const [selectedRoom, setSelectedRoom] = useState(undefined);
  const [lastSave, setLastSave] = useState(undefined);

  useEffect(() => {
    if (selectedRoom === undefined && device._room) {
      setSelectedRoom(device._room);
      setLastSave(device._room);
    }
  }, []);

  const getPickerItems = () => {
    return rooms.length > 0 ? (
      rooms.map((room) => <Picker.Item key={room._id} label={room.name} value={room._id} />)
    ) : (
      <Picker.Item key="room-placeholder" label="Sélectionner une salle" />
    );
  };

  const onRoomPickerChange = (itemValue, itemIndex) => {
    setSelectedRoom(itemValue);
  };

  const onSave = () => {
    requestUpdateDevice(device._id, { _room: selectedRoom });
    setLastSave(selectedRoom);
  };

  const isDisabled = () => {
    return selectedRoom === lastSave;
  };

  return (
    <View style={styles.settingContainer}>
      <Picker
        selectedValue={selectedRoom}
        onValueChange={onRoomPickerChange}
        enabled={rooms.length > 0}
      >
        {getPickerItems()}
      </Picker>
      <View>
        <Button title="Sauvegarder" onPress={onSave} disabled={isDisabled()} />
      </View>
    </View>
  );
};

SettingDevice.propTypes = {
  device: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  requestUpdateDevice: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestUpdateDevice: (_id, body) => {
      return dispatch(deviceActions.requestUpdateDevice(_id, body));
    },
  };
};

export default connect(null, mapDispatchToProps)(SettingDevice);
