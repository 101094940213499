import { StyleSheet, Text, View, FlatList } from 'react-native';
import React from 'react';

import PlansMobile from './SettingAffichagePlanMobile';
import FondVert from './SettingAffichageFondVertMobile';
import Resolution from './SettingAffichageResolutionMobile';
import Scene from './SettingAffichageSceneMobile';

import colors from '../../../constants/colors';

const styles = StyleSheet.create({
  buttonMenu: {
    flex: 1,
  },
  SettingsAffichage: {
    backgroundColor: colors.getMirage(),
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  navButtons: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navButtonsSelected: {
    flex: 4,
    backgroundColor: colors.getMainPurple(),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  menuIcon: {
    margin: 8,
    marginLeft: 12,
    alignSelf: 'center',
  },
  menuText: {
    margin: 8,
    color: 'rgba(0, 0, 0, 0.4)',
  },
  menuSelectedText: {
    margin: 8,
    color: 'white',
  },
  isSelected: {
    flex: 3,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.24,
    color: colors.getMainWhite(),
    marginHorizontal: 5,
  },
  isNotSelected: {
    flex: 3,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.24,
    color: colors.getMainWhite(0.3),
    marginHorizontal: 5,
  },
  rectangle: {
    height: 1,
    marginTop: 9,
    marginLeft: 145,
    backdropFilter: 'blur(10px)',
    backgroundColor: colors.getMainPurple(),
  },
  rectanglePlans: {
    height: 1,
    marginTop: 9,
    backdropFilter: 'blur(10px)',
    backgroundColor: colors.getMainPurple(),
  },
});

export default class SettingAffichageMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: 'Plans',
    };
  }

  render() {
    return (
      <View style={styles.SettingsAffichage}>
        <View
          style={{
            flexDirection: 'row',
            width: 'auto',
            paddingVertical: 10,
            justifyContent: 'center',
          }}
        >
          <FlatList
            centerContent={true}
            numColumns={4}
            data={[
              { isSelected: true, key: 'Plans' },
              { isSelected: false, key: 'Fond vert' },
              { isSelected: false, key: 'Résolution' },
              { isSelected: false, key: 'Scènes' },
            ]}
            renderItem={({ item }) => this.renderMenu(item)}
          />
        </View>
        <View style={{ flex: 12, width: '100%', height: '100%' }}>{this.changeStagteMenu()}</View>
      </View>
    );
  }

  renderMenu = (item) => {
    if (item.key === this.state.selectedMenu) {
      return (
        <Text style={styles.isSelected} onPress={() => this.setState({ selectedMenu: item.key })}>
          {item.key}
        </Text>
      );
    }
    return (
      <Text style={styles.isNotSelected} onPress={() => this.setState({ selectedMenu: item.key })}>
        {item.key}
      </Text>
    );
  };

  changeStagteMenu = () => {
    if (this.state.selectedMenu === 'Plans') return <PlansMobile />;
    if (this.state.selectedMenu === 'Fond vert') return <FondVert />;
    if (this.state.selectedMenu === 'Résolution') return <Resolution />;
    if (this.state.selectedMenu === 'Scènes') return <Scene />;
    return null;
  };
}
