import { all } from 'redux-saga/effects';
import user from './user';
import organization from './organization';
import activity from './activity';
import room from './room';
import error from './error';
import device from './device';

export default function* sagas() {
  yield all([...user, ...organization, ...activity, ...room, ...device, ...error]);
}
