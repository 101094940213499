import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Chat from '../../containers/Chat';
import ConnectedUsers from '../../containers/ConnectedUsers';

import colors from '../../constants/colors';
import { sNavBarTop } from '../../styles/fixed';

import IconChat from '../../assets/blue/icon-chat-white.svg';
import IconClose from '../../assets/blue/close-chat.svg';
import IconMember from '../../assets/blue/icon-membres.svg';
import roomSelectors from '../../App/selectors/room';
import roomActions from '../../App/actions/room';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    borderLeftWidth: 0.5,
    borderLeftColor: colors.getGraySantas(),
    maxHeight: '100vh',
  },
  logos: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 45,
  },
  logo: {
    justifyContent: 'center',
    alignContent: 'center',
    marginRight: 42,
  },
  line: {
    backgroundColor: colors.getMainPurple(),
    height: 4,
    width: 45,
    position: 'absolute',
    top: 50,
    left: -10,
  },
  containerheader: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.getMirage(),
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderBottomColor: colors.getGraySantas(),
    height: sNavBarTop.height,
    minHeight: sNavBarTop.height, // Won't stay fixed at 80 otherwise
    maxHeight: sNavBarTop.height, // Won't stay fixed at 80px otherwise
  },
  close: {
    position: 'absolute',
    top: 15,
    right: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  body: {
    flex: 11,
    height: '100vh',
  },
});

const CHAT = 'chat';
const MEMBER = 'member';

const RightMenu = (props) => {
  const [selectedTab, setSelectedTab] = useState(CHAT);

  const displayTab = () => {
    if (selectedTab === CHAT) return <Chat />;
    return <ConnectedUsers />;
  };

  const selectedTabLine = (tabSelected) => {
    if (selectedTab === tabSelected) return <View style={styles.line} />;
    return null;
  };

  return (
    <View style={styles.container}>
      <View style={styles.containerheader}>
        <View style={styles.logos}>
          <View style={styles.logo}>
            <TouchableOpacity onPress={() => setSelectedTab(CHAT)}>
              <IconChat />
            </TouchableOpacity>
            {selectedTabLine(CHAT)}
          </View>
          <View style={styles.logo}>
            <TouchableOpacity onPress={() => setSelectedTab(MEMBER)}>
              <IconMember />
            </TouchableOpacity>
            {selectedTabLine(MEMBER)}
          </View>
        </View>
        <View style={styles.close}>
          <TouchableOpacity onPress={() => props.setDisplayRightMenu(false)}>
            <IconClose />
          </TouchableOpacity>
        </View>
      </View>
      {displayTab()}
    </View>
  );
};

RightMenu.propTypes = {
  displayRightMenu: PropTypes.bool.isRequired,

  // Action
  setDisplayRightMenu: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDisplayRightMenu: (bool) => dispatch(roomActions.setDisplayRightMenu(bool)),
  };
};

const mapStateToProps = createStructuredSelector({
  displayRightMenu: roomSelectors.makeSelectDisplayRightMenu(),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
