import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/activity';

const initialState = fromJS({
  availableRooms: [],
  info: {},
});

export const setAvailableRooms = (state = initialState, { rooms }) =>
  state.set('availableRooms', fromJS(rooms));

export const setActivityInfo = (state = initialState, { activityInfo }) =>
  state.set('info', fromJS(activityInfo));

export default createReducer(initialState, {
  [Types.SET_AVAILABLE_ROOMS]: setAvailableRooms,
  [Types.SET_ACTIVITY_INFO]: setActivityInfo,
});
