import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestUpdateDevice: ['_id', 'body'],

  // Request Socket
  move: ['direction'],
  stopMove: [],
  startLive: [],
  stopLive: [],

  // Set reducers
  setDevice: ['device'],
  setDeviceStatus: ['status'], // status = { online: bool, liveStartedAt: datetime|undefined, recordStartedAt: datetime|undefined }
});

export { Types };
export default Creators;
