import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import Slider from '@react-native-community/slider';
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import colors, { PURPLE } from '../../../constants/colors';

import systemSelectors from '../../../App/selectors/system';
import systemActions from '../../../App/actions/system';

import IconCamera from '../../../assets/settings/icon-view-camera.svg';
import IconPresentation from '../../../assets/settings/icon-view-presentation.svg';
import IconMosaiqueGauche from '../../../assets/settings/icon-view-mosaic-left.svg';
import TempIconSound from '../../../assets/settings/temp-icon-sound.svg';

const IconHdmi = require('../../../assets/modals/icon-video-settings.png');
const IconMicro = require('../../../assets/modals/icon-micro-settings.png');

const styles = StyleSheet.create({
  SettingsAffichage: {
    flex: 6,
    backgroundColor: colors.getGrayAthens(),
    flexDirection: 'column',
  },
  container: {
    height: 'auto',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    paddingBottom: 30,
    paddingHorizontal: 17,
  },
  TextCopy: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.45,
    letterSpacing: 0.22,
    color: colors.getBluePrussian(),
    marginTop: 35,
    marginBottom: 45,
  },
  TextInfo: {
    fontSize: 16,
    lineHeight: 1.81,
    letterSpacing: 0.17,
    color: colors.getBluePrussian(),
    marginBottom: 20,
  },
  listebutton: {
    flexDirection: 'row',
    marginTop: 50,
    marginHorizontal: 'auto',
  },
  button: {
    width: 105,
    height: 160,
    borderRadius: 20,
    backgroundColor: colors.getMainWhite(),
    marginRight: 20,
    alignItems: 'center',
  },
  buttonEnd: {
    width: 105,
    height: 160,
    borderRadius: 20,
    backgroundColor: colors.getMainWhite(),
    alignItems: 'center',
  },
  ButtonActive: {
    width: 107,
    height: 160,
    borderRadius: 20,
    border: `solid 2px${colors.getGallery()}`,
    backgroundColor: colors.getMainWhite(),
    marginRight: 20,
    alignItems: 'center',
  },
  ButtonActiveEnd: {
    width: 107,
    height: 160,
    borderRadius: 20,
    border: `solid 2px${colors.getGallery()}`,
    backgroundColor: colors.getMainWhite(),
    alignItems: 'center',
  },
  IconButton: {
    paddingTop: 35,
    paddingBottom: 20,
  },
  TextButton: {
    fontSize: 15,
    lineHeight: 1.93,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.getBluePrussian(),
  },
  modalSetting: {
    marginTop: 30,
    flexDirection: 'column',
    borderRadius: 16,
    backdropFilter: 'blur(10px)',
    backgroundColor: colors.getMainWhite(),
    marginHorizontal: 'auto',
    paddingVertical: 35,
    paddingHorizontal: 24,
  },
  TextModal: {
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.22,
    color: colors.getBluePrussian(),
    marginVertical: 'auto',
  },
  slider: {
    marginTop: 27,
    flexDirection: 'row',
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: colors.getGallery(),
    width: 30,
    height: 30,
    marginRight: 21,
    justifyContent: 'center',
    alignItems: 'center',
  },
  triangle: {
    marginHorizontal: 'auto',
    top: 45,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 30,
    borderRightWidth: 30,
    borderBottomWidth: 30,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'white',
  },
  enregistrer: {
    paddingVertical: 15,
    paddingHorizontal: 53,
    backgroundColor: PURPLE,
    borderRadius: 16,
    marginVertical: 24,
    marginHorizontal: 'auto',
    width: 200,
  },
});

class SettingHdmi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 1,
    };
  }

  render() {
    return (
      <View style={styles.SettingsAffichage}>
        <View style={{ flex: 10, height: 1 }}>
          <View style={styles.container}>
            <View>
              <Text style={styles.TextCopy}>Recopie vidéo</Text>
              <Text style={styles.TextInfo}>Quel contenu souhaitez-vous retransmettre</Text>
              <Text style={styles.TextInfo}>depuis la sortie HDMI de la caméra ?</Text>
            </View>
            <View style={styles.listebutton}>
              <View
                style={this.state.selected === 1 ? styles.ButtonActive : styles.button}
                onClick={() => this.setState({ selected: 1 })}
              >
                <IconPresentation style={styles.IconButton} />
                <Text style={styles.TextButton}>Présentation</Text>
                {this.state.selected === 1 ? <View style={styles.triangle} /> : null}
              </View>
              <View
                style={this.state.selected === 2 ? styles.ButtonActive : styles.button}
                onClick={() => this.setState({ selected: 2 })}
              >
                <IconCamera style={styles.IconButton} />
                <Text style={styles.TextButton}>Caméra</Text>
                {this.state.selected === 2 ? <View style={styles.triangle} /> : null}
              </View>
              <View
                style={this.state.selected === 3 ? styles.ButtonActiveEnd : styles.buttonEnd}
                onClick={() => this.setState({ selected: 3 })}
              >
                <IconMosaiqueGauche style={styles.IconButton} />
                <Text style={styles.TextButton}>Vue composée</Text>
                {this.state.selected === 3 ? <View style={styles.triangle} /> : null}
              </View>
            </View>
            {this.getSetting()}
          </View>
        </View>
        <TouchableOpacity style={styles.enregistrer}>
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              textAlign: 'center',
              color: colors.getMainWhite(),
            }}
          >
            Enregistrer
          </Text>
        </TouchableOpacity>
      </View>
    );
  }

  getSetting = () => {
    if (this.state.selected === 1) {
      return (
        <View>
          <View style={styles.modalSetting}>
            <View style={{ flexDirection: 'column' }}>
              <View style={{ flexDirection: 'row' }}>
                <img src={IconHdmi} />
                <Text style={styles.TextModal}>HDMI : Ordinateur</Text>
              </View>
              <View style={styles.slider}>
                <View style={styles.circle}>
                  <TempIconSound style={{ width: 19, height: 16 }} />
                </View>
                <Slider
                  vertical={true}
                  style={{ width: 200, height: 30 }}
                  minimumValue={0}
                  maximumValue={100}
                  minimumTrackTintColor={colors.getMainPurple()}
                  maximumTrackTintColor="rgba(220, 220, 220, 0.9)"
                  thumbTintColor="grey"
                  step={1}
                  onValueChange={(val) => this.props.sendHdmi(val, this.props.audio.line.volume)}
                  value={this.props.audio.hdmi.volume}
                />
              </View>
            </View>
          </View>
        </View>
      );
    }
    if (this.state.selected === 2) {
      return (
        <View>
          <View style={styles.modalSetting}>
            <View style={{ flexDirection: 'column' }}>
              <View style={{ flexDirection: 'row' }}>
                <img src={IconMicro} />
                <Text style={styles.TextModal}>AVX Video Sound</Text>
              </View>
              <View style={styles.slider}>
                <View style={styles.circle}>
                  <TempIconSound style={{ width: 19, height: 16 }} />
                </View>
                <Slider
                  vertical={true}
                  style={{ width: 200, height: 30 }}
                  minimumValue={0}
                  maximumValue={100}
                  minimumTrackTintColor={colors.getGallery()}
                  maximumTrackTintColor="rgba(220, 220, 220, 0.9)"
                  thumbTintColor="grey"
                  step={1}
                  onValueChange={(val) => this.props.sendHdmi(this.props.audio.line.volume, val)}
                  value={this.props.audio.line.volume}
                />
              </View>
            </View>
          </View>
        </View>
      );
    }
    if (this.state.selected === 3) {
      return (
        <View>
          <View style={styles.modalSetting}>
            <View style={{ flexDirection: 'column' }}>
              <View style={{ flexDirection: 'row' }}>
                <img src={IconHdmi} />
                <Text style={styles.TextModal}>HDMI : Ordinateur</Text>
              </View>
              <View style={styles.slider}>
                <View style={styles.circle}>
                  <TempIconSound style={{ width: 19, height: 16 }} />
                </View>
                <Slider
                  vertical={true}
                  style={{ width: 200, height: 30 }}
                  minimumValue={0}
                  maximumValue={100}
                  minimumTrackTintColor={colors.getGallery()}
                  maximumTrackTintColor="rgba(220, 220, 220, 0.9)"
                  thumbTintColor="grey"
                  step={1}
                  onValueChange={(val) => this.props.sendHdmi(val, this.props.audio.line.volume)}
                  value={this.props.audio.hdmi.volume}
                />
              </View>
            </View>
            <View style={{ flexDirection: 'column' }}>
              <View style={{ flexDirection: 'row' }}>
                <img src={IconMicro} />
                <Text style={styles.TextModal}>AVX Video Sound</Text>
              </View>
              <View style={styles.slider}>
                <View style={styles.circle}>
                  <TempIconSound style={{ width: 19, height: 16 }} />
                </View>
                <Slider
                  vertical={true}
                  style={{ width: 200, height: 30 }}
                  minimumValue={0}
                  maximumValue={100}
                  minimumTrackTintColor={colors.getGallery()}
                  maximumTrackTintColor="rgba(220, 220, 220, 0.9)"
                  thumbTintColor="grey"
                  step={1}
                  onValueChange={(val) => this.props.sendHdmi(this.props.audio.hdmi.volume, val)}
                  value={this.props.audio.line.volume}
                />
              </View>
            </View>
          </View>
        </View>
      );
    }
    return null;
  };
}

SettingHdmi.propTypes = {
  audio: PropTypes.object.isRequired,

  // Actions
  sendHdmi: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  audio: systemSelectors.makeSelectAudio(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendHdmi: (stringOne, stringTwo) => dispatch(systemActions.sendHdmi(stringOne, stringTwo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingHdmi);
