import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NavBarTop from '../../containers/NavBarTop';
import Player from '../../containers/Player';
import RightMenu from './rightMenu';

import { userRoleIsGTE } from '../../helpers/role';

import colors from '../../constants/colors';
import { USER_ROLE } from '../../constants/global';
import { screenName } from '../../constants/navigation';
import { sGlobal } from '../../styles/fixed';

import userSelectors from '../../App/selectors/user';
import roomSelectors from '../../App/selectors/room';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.getMirage(),
    flexDirection: 'row',
  },
  main: {
    flexDirection: 'column',
    flex: 5,
    paddingLeft: sGlobal.padding,
    paddingRight: sGlobal.padding,
  },
  rightMenu: {
    flex: 2,
    backgroundColor: colors.getMirage(),
  },
  elements: {
    flex: 5,
    maxWidth: '20%',
    maxHeight: '100vh',
    overflow: 'auto',
    borderRightWidth: 0.5,
    borderRightColor: colors.getGraySantas(),
  },
});

const Home = (props) => {
  useEffect(() => {
    if (props.room._id === undefined) props.navigation.navigate(screenName.ROOMS);
  }, []);

  const displayRightMenu = () => (
    <View style={styles.rightMenu}>
      <RightMenu />
    </View>
  );

  const hasControlRights = () => {
    return userRoleIsGTE(props.user.role, USER_ROLE.PROFESSOR);
  };

  return (
    <View style={styles.container}>
      <View style={[styles.main, { paddingBottom: hasControlRights() ? 0 : sGlobal.padding }]}>
        <NavBarTop />
        <Player source={props.room?.urlPlayer} autoPlay hasControlRights={hasControlRights()} />
      </View>
      {props.displayRightMenu ? displayRightMenu() : null}
    </View>
  );
};

Home.propTypes = {
  navigation: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  displayRightMenu: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  room: roomSelectors.makeSelectRoom(),
  displayRightMenu: roomSelectors.makeSelectDisplayRightMenu(),
  user: userSelectors.makeSelectUserInfo(),
});

export default connect(mapStateToProps, null)(Home);
