import { requestAPI } from '../../helpers/request';
import { getAuthHeader } from '../../helpers/auth';

const basePath = '/organization';

const requestAuthInfo = (domain) => {
  const path = `${basePath}/auth/info`;

  return requestAPI(
    path,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { domain },
  )
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestOrganization = (id) => {
  const path = `${basePath}/${id}`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestOrganizations = () => {
  const path = `${basePath}`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestRooms = (organizationId) => {
  const path = `${basePath}/${organizationId}/room`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestRoom = (organizationId, roomId) => {
  const path = `${basePath}/${organizationId}/room/${roomId}`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

export default {
  requestAuthInfo,
  requestOrganization,
  requestOrganizations,
  requestRooms,
  requestRoom,
};
