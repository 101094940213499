import { StyleSheet, Text, View, Platform, Image, FlatList } from 'react-native';
import React from 'react';

import IconUsb from '../../../assets/modals/icon-usb.svg';
import IconFtp from '../../../assets/modals/icon-ftp.svg';
import IconNothing from '../../../assets/modals/icon-nothing.svg';
import colors from '../../../constants/colors';
import WebModalFtp from '../modals/WebModalFtp';

const DropBox = require('../../../assets/modals/icon-dropbox.jpg');
const GoogleDrive = require('../../../assets/modals/icon-twitch.jpg');

const styles = StyleSheet.create({
  Streaming: {
    flex: 6,
    paddingHorizontal: 15,
    paddingTop: 24,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
  },
  TitleText: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.getBluePrussian(),
  },
  IconStream: {
    marginHorizontal: 'auto',
    marginTop: 24,
    marginBottom: 13,
    width: 40,
    height: 40,
  },
  TextIcon: {
    fontSize: 13,
    textAlign: 'center',
    color: colors.getBluePrussian(),
  },
  TextIconAdd: {
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    color: colors.getMainPurple(),
  },
  listContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
  },
});

export default class SettingPlatformesStockage extends React.Component {
  data = [
    { id: 1, type: 'ajout', name: 'Ajouter' },
    { id: 2, type: 'aucun', name: 'Aucun' },
    { id: 3, type: 'usb', name: 'JARVIS (USB)' },
    { id: 4, type: 'GoogleDrive', name: 'Google Drive' },
    { id: 5, type: 'DropBox', name: 'DropBox Atrioom' },
    { id: 6, type: 'ftp', name: 'Atrioom FTP' },
  ];

  render() {
    return (
      <View style={styles.Streaming}>
        <Text style={styles.TitleText}>Mes plateformes de stockage</Text>
        <FlatList
          horizontal={true}
          data={this.data}
          renderItem={this.selectionList}
          keyExtractor={(item) => `${item.id}`}
        />
      </View>
    );
  }

  selectionList = ({ item }) => {
    switch (item.type) {
      case 'ajout':
        return <WebModalFtp key="ajout" />;
      case 'aucun':
        return (
          <View
            key="aucun"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            <IconNothing style={styles.IconStream} />
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      case 'usb':
        return (
          <View
            key="usb"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            <IconUsb style={styles.IconStream} />
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      case 'GoogleDrive':
        return (
          <View
            key="GoogleDrive"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            {this.getPicture(GoogleDrive)}
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      case 'DropBox':
        return (
          <View
            key="DropBox"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            {this.getPicture(DropBox)}
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      case 'ftp':
        return (
          <View
            key="ftp"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            <IconFtp style={styles.IconStream} />
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      default:
        return null;
    }
  };

  getPicture = (type) => {
    if (Platform.OS === 'web') {
      return (
        <img
          src={type}
          style={{
            marginLeft: 34,
            marginRight: 34,
            marginTop: 24,
            marginBottom: 13,
            width: 40,
            height: 40,
          }}
        />
      );
    }
    return <Image source={type} style={styles.IconStream} />;
  };
}
