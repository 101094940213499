import { requestAPI } from '../../helpers/request';
import { getAuthHeader } from '../../helpers/auth';

const basePath = '/user';

const requestLoginLocal = (email, password, domain) => {
  const path = `${basePath}/login/local`;

  return requestAPI(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      domain,
    }),
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestLoginCas = (ticket, hostname) => {
  const path = `${basePath}/login/cas`;

  return requestAPI(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ticket,
      hostname,
    }),
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

const requestUser = () => {
  const path = `${basePath}/me`;
  const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }

  return requestAPI(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      throw error;
    });
};

export default {
  requestLoginLocal,
  requestLoginCas,
  requestUser,
};
