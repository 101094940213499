export const WEB_PLATFORM = 'web';

// Take care if you change the following, also change the hard coded string in scripts/start.js
export const ENV_LOCAL = 'local';
export const ENV_STAGING = 'staging';
export const ENV_PROD = 'prod';

const APP_DOMAIN = 'kast.app';
export const DOMAIN_BY_ENV = {
  [ENV_LOCAL]: `local.${APP_DOMAIN}`,
  [ENV_STAGING]: `staging.${APP_DOMAIN}`,
  [ENV_PROD]: `${APP_DOMAIN}`,
};

export const AUTH_TYPE = {
  LOCAL: 'local',
  CAS: 'cas',
  GOOGLE: 'google',
  OFFICE365: 'office365',
};

export const USER_ROLE = {
  ADMIN: 'admin',
  TECHNICIAN: 'technician',
  PROFESSOR: 'professor',
  STUDENT: 'student',
  GUEST: 'guest',
};

export const USER_ROLE_TO_INT = {
  [USER_ROLE.ADMIN]: 0,
  [USER_ROLE.TECHNICIAN]: 1,
  [USER_ROLE.PROFESSOR]: 2,
  [USER_ROLE.STUDENT]: 3,
  [USER_ROLE.GUEST]: 4,
};

export default {
  WEB_PLATFORM,
  AUTH_TYPE,
  ENV_LOCAL,
  ENV_STAGING,
  ENV_PROD,
  DOMAIN_BY_ENV,
  USER_ROLE,
  USER_ROLE_TO_INT,
};
