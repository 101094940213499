import { io } from 'socket.io-client';

import roomActions, { Types as roomTypes } from '../App/actions/room';
import organizationActions from '../App/actions/organization';
import { Types as appTypes } from '../App/actions/app';
import deviceActions, { Types as deviceTypes } from '../App/actions/device';
import { ENV_LOCAL, ENV_STAGING, ENV_PROD } from '../constants/global';
import { DEVICE_RESPONSE_SUCCESS } from '../constants/socket';

const { SET_ROOM, SET_OWN_MESSAGE } = roomTypes;
const { SOCKET_CONNECT, SOCKET_DISCONNECT, SOCKET_REQUEST_DEVICES } = appTypes;
const { MOVE, STOP_MOVE, START_LIVE, STOP_LIVE } = deviceTypes;

/**
 * Get the current backend base (root) url depending on env variable
 *
 * @returns {string} Returns a url as string
 */
const getBaseUrl = () => {
  if (process.env.ENVIRONMENT === ENV_LOCAL) return 'wss://api.local.kast.app';
  if (process.env.ENVIRONMENT === ENV_STAGING) return 'wss://api.staging.kast.app';
  if (process.env.ENVIRONMENT === ENV_PROD) return 'wss://api.kast.app';
  return '';
};

export const socketMiddleware = () => {
  let socket;
  return (storeAPI) => (next) => (action) => {
    const { dispatch, getState } = storeAPI;

    const currState = getState();

    // Priority to connecting the socket. (Happens after we fetched the organization)
    if (action.type === SOCKET_CONNECT) {
      socket = io(`${getBaseUrl()}/${currState.getIn(['organization', '_id'])}`, {
        reconnection: false,
        transports: ['websocket'],
      });

      // Handlers server sockets
      socket.on('userJoinRoom', (user) => dispatch(roomActions.setConnectedUser(user)));
      socket.on('userLeaveRoom', (user) => dispatch(roomActions.removeConnectedUser(user)));
      socket.on('messageRoom', (message) => dispatch(roomActions.setIncomingMessage(message)));
      socket.on('joinRoom', (users, messages) => {
        dispatch(roomActions.setConnectedUsers(users));
        dispatch(roomActions.setMessages(messages));
      });
      socket.on('app_request_devices', (ids) =>
        dispatch(organizationActions.setOnlineDevices(ids)),
      );

      // Handlers for Kast Camera response (via backend socket)
      socket.on('app_move', (data) => {
        console.log('camera moved');
      }); // TODO: Do something with the response!
      socket.on('app_stop_move', (data) => {
        console.log('camera stopped moving');
      }); // TODO: Do something with the response!

      socket.on('app_start_live_session', (data) => {
        if (data.type === DEVICE_RESPONSE_SUCCESS) {
          const status = { liveStartedAt: data.liveStartedAt };
          dispatch(deviceActions.setDeviceStatus(status));
        } else {
          alert(data.content.error_message);
        }
      });

      socket.on('app_stop_live_session', (data) => {
        if (data.type === DEVICE_RESPONSE_SUCCESS) {
          const status = { liveStartedAt: data.liveStartedAt };
          dispatch(deviceActions.setDeviceStatus(status));
        } else {
          alert(data.content.error_message);
        }
      });

      return next(action);
    }

    // Disconnect the socket (on demand). Close tab / browser is handled by socket.io lib and the server
    if (action.type === SOCKET_DISCONNECT) {
      if (socket) socket.disconnect();
      return next(action);
    }

    // If the action is NOT to connect AND the socket is undefined, keep going
    if (socket === undefined) return next(action);

    // Starting from here, order is not realy important and we can use switch case if we want

    // If the user change room, leave the current one and join the "next" one
    if (action.type === SET_ROOM) {
      // Avoid call when joining room for the first time (on mount) => There is no room to leave..
      if (currState.getIn(['room', '_id'])) socket.emit('leaveRoom');

      const nextState = next(action);

      const userState = currState.get('user').toJS();
      const user = userState.info;
      // emit("joinRoom", user, room)
      socket.emit('joinRoom', user, nextState.room);

      return nextState;
    }

    // If the user send message
    if (action.type === SET_OWN_MESSAGE) {
      const nextState = next(action);
      socket.emit('messageRoom', nextState.message);
      return nextState;
    }

    if (action.type === SOCKET_REQUEST_DEVICES) {
      socket.emit('app_request_devices');
      return next(action);
    }

    // Move the Kast Camera
    if (action.type === MOVE) {
      const data = {
        device: currState.getIn(['device', 'info']).toJS(),
        direction: action.direction,
      };
      socket.emit('app_move', data);
      return next(action);
    }

    if (action.type === STOP_MOVE) {
      const data = {
        device: currState.getIn(['device', 'info']).toJS(),
      };
      socket.emit('app_stop_move', data);
      return next(action);
    }

    // Start Kast Camera live
    if (action.type === START_LIVE) {
      const data = { device: currState.getIn(['device', 'info']).toJS() };
      socket.emit('app_start_live_session', data);
      return next(action);
    }

    // Stop Kast Camera live
    if (action.type === STOP_LIVE) {
      const data = { device: currState.getIn(['device', 'info']).toJS() };
      socket.emit('app_stop_live_session', data);
      return next(action);
    }

    // Else, return next(action) => No "modification" made by this middleware
    return next(action);
  };
};

export default {
  socketMiddleware,
};
