import { StyleSheet, Text, View, Switch, TouchableOpacity } from 'react-native';
import Slider from '@react-native-community/slider';
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import colors, { PURPLE, GREY, WHITE } from '../../../constants/colors';

import ZoomPlus from '../../../assets/settings/icon-zoom-plus.svg';
import ZoomLess from '../../../assets/settings/icon-zoom-less.svg';
import FocusLess from '../../../assets/settings/icon-focus-less.svg';
import FocusMore from '../../../assets/settings/icon-focus-more.svg';
import IconPipette from '../../../assets/settings/icon-pipette.svg';
import IconHelp from '../../../assets/settings/icon-help.svg';

import systemSelectors from '../../../App/selectors/system';
import systemActions from '../../../App/actions/system';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    flexDirection: 'row',
  },
  SettingsAffichagePlan: {
    flex: 12,
    backgroundColor: 'white',
    flexDirection: 'row',
    marginLeft: 100,
  },
  left: {
    flex: 10,
    flexDirection: 'colunm',
  },
  cameraView: {
    flex: 8,
    borderRadius: 8,
    backgroundImage: `linear-gradient(291deg, ${colors.getRoseOld()}98%, ${colors.getClamShell()} 3%)`,
  },
  focusView: {
    flex: 2,
    marginTop: 13,
    flexDirection: 'row',
  },
  RightMenu: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    // marginBottom: 280,
  },
  switch: {
    width: 194,
    height: 57,
    flexDirection: 'row',
    paddingTop: 18,
    paddingRigth: 23,
    paddingBottom: 16,
    paddingLeft: 26,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    marginRight: 11,
  },
  Zoom: {
    position: 'absolute',
    right: 0,
    height: 57,
    paddingVertical: 11,
    paddingHorizontal: 16,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    flexDirection: 'row',
  },
  enregistrer: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    backgroundColor: PURPLE,
    borderRadius: 16,
    marginBottom: 24,
    marginHorizontal: 'auto',
    width: 178,
    height: 50,
  },
  MoreLess: {
    height: 57,
    flexDirection: 'row',
    paddingVertical: 11,
    paddingHorizontal: 16,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
  },
  Pipette: {
    flexDirection: 'row',
    paddingVertical: 14,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    marginBottom: 18,
  },
  circle: {
    width: 35,
    height: 35,
    backgroundColor: colors.getBlueDodger(),
    borderRadius: '50%',
    marginRight: 11,
  },
  slider: {
    paddingTop: 15.5,
    paddingHorizontal: 4,
    paddingBottom: 9.5,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
  },
  textSlide: {
    marginRight: 2,
    marginLeft: 22.5,
    fontSize: 11,
    letterSpacing: 0.15,
    textAlign: 'center',
    color: colors.getBluePrussian(),
  },
  helps: {
    marginTop: 25,
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
  },
  TextAide: {
    fontSize: 13,
    letterSpacing: 0.18,
    textAlign: 'center',
    color: colors.getMainPurple(),
    marginLeft: 11,
    marginVertical: 'auto',
  },
});

class SettingAffichageFondVert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AlphaAngle: this.props.video.Angle,
      AlphaBW: this.props.video.BW,
    };
  }

  render() {
    return (
      <View style={styles.SettingsAffichagePlan}>
        <View style={styles.left}>
          <View style={styles.cameraView}></View>
          <View style={styles.focusView}>
            <View style={styles.switch}>
              <Text style={{ fontSize: 16, color: colors.getBluePrussian(), marginRight: 28 }}>
                Focus auto
              </Text>
              <Switch
                trackColor={{ false: GREY, true: PURPLE }}
                thumbColor={this.state.FocusValue ? WHITE : WHITE}
                ios_backgroundColor={colors.getMainWhite()}
                onValueChange={() => this.props.switchFocus(!this.props.video.focus)}
                value={this.props.video.focus}
              />
            </View>
            <View style={styles.MoreLess}>
              <FocusLess style={{ marginRight: 16 }} />
              <FocusMore />
            </View>
            <View style={styles.Zoom}>
              <ZoomLess style={{ marginRight: 13 }} />
              <ZoomPlus />
            </View>
          </View>
          <TouchableOpacity
            style={styles.enregistrer}
            onPress={() =>
              this.props.sendAlpha({
                Angle: this.state.AlphaAngle,
                BW: this.state.AlphaBW,
              })
            }
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
                textAlign: 'center',
                color: colors.getMainWhite(),
              }}
            >
              Enregistrer
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.RightMenu}>
          <View style={styles.Pipette}>
            <View style={styles.circle}></View>
            <IconPipette />
          </View>
          <View style={styles.slider}>
            <View>
              <Text style={styles.textSlide}>Alpha angle</Text>
              <Slider
                vertical={true}
                style={{ width: 200, height: 40 }}
                minimumValue={0}
                maximumValue={100}
                minimumTrackTintColor={PURPLE}
                maximumTrackTintColor={GREY}
                step={1}
                value={this.props.video.alpha.Angle}
                onValueChange={(val) => this.setState({ AlphaAngle: val })}
              />
              <Text style={styles.textSlide}>
                {this.state.AlphaAngle === 0 ? this.props.video.alpha.Angle : this.state.AlphaAngle}
                %
              </Text>
            </View>
            <View>
              <Text style={styles.textSlide}>Alpha B/W</Text>
              <Slider
                vertical={true}
                style={{ width: 200, height: 40 }}
                minimumValue={0}
                maximumValue={100}
                minimumTrackTintColor={PURPLE}
                maximumTrackTintColor={GREY}
                step={1}
                value={this.props.video.alpha.BW}
                onValueChange={(val) => this.setState({ AlphaBW: val })}
              />
              <Text style={styles.textSlide}>
                {this.state.AlphaBW === 0 ? this.props.video.alpha.BW : this.state.AlphaBW}%
              </Text>
            </View>
          </View>
          <View style={styles.helps}>
            <IconHelp />
            <Text style={styles.TextAide}>Aide</Text>
          </View>
        </View>
      </View>
    );
  }
}

SettingAffichageFondVert.propTypes = {
  video: PropTypes.object.isRequired,

  // Actions
  switchFocus: PropTypes.func.isRequired,
  sendAlpha: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  video: systemSelectors.makeSelectVideo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    switchFocus: (boolean) => dispatch(systemActions.switchFocus(boolean)),
    sendAlpha: (object) => dispatch(systemActions.sendAlpha(object)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingAffichageFondVert);
