import { FlatList, StyleSheet, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import roomSelectors from '../../App/selectors/room';
import ConnectedElement from '../../components/ConnectedElement';
import CustomImage from '../../components/CustomImage';

import IconUser from '../../assets/user/user-placeholder.svg';

const styles = StyleSheet.create({
  topContainer: {
    flex: 1.5,
    flexDirection: 'column',
  },
  container: {
    flex: 10,
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
  },
});

const styleSquare = {
  width: 50,
  height: 50,
  minWidth: 50,
  minHeight: 50,
};

const styleWeb = {
  ...styleSquare,
  borderRadius: '50%',
};

const style = {
  ...styleSquare,
  borderRadius: 50,
};

const ConnectedUsers = ({ connectedUsers }) => {
  const buildAvatar = (avatar) => {
    if (avatar === null || avatar === undefined) return <IconUser style={styleWeb} />;
    return <CustomImage image={avatar} style={style} styleWeb={styleWeb} />;
  };

  const renderItem = ({ username, avatar }) => (
    <ConnectedElement text={username} icon={buildAvatar(avatar)} />
  );

  return (
    <View style={styles.topContainer}>
      <FlatList
        style={styles.container}
        data={connectedUsers}
        renderItem={({ item }) => renderItem(item)}
        keyExtractor={(item) => `${item._id}`}
      />
    </View>
  );
};

ConnectedUsers.propTypes = {
  connectedUsers: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  connectedUsers: roomSelectors.makeSelectConnectedUsers(),
});

export default connect(mapStateToProps, null)(ConnectedUsers);
