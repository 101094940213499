const LOGIN = 'login';
const LOGOUT = 'logout';
const HOME = 'embedded';
const ORGANIZATIONS = 'organizations';
const ROOMS = 'rooms';
const SETTINGS = 'settings';
const MEDIAS = 'medias';
const HELP = 'help';
const ACTIVITY_SCREENS = 'activityScreens';
const SCHEDULE = 'schedule';
const ACTIVITY = 'activity';
const PANEL = 'panel';

export const screenName = {
  LOGIN,
  LOGOUT,
  HOME,
  ORGANIZATIONS,
  ROOMS,
  SETTINGS,
  MEDIAS,
  HELP,
  ACTIVITY_SCREENS,
  SCHEDULE,
  ACTIVITY,
  PANEL,
};

// TODO: Add not found page!

export const screen = {
  [LOGIN]: { path: 'login' },
  [LOGOUT]: { path: 'logout' },
  [HOME]: { path: 'embedded' },
  [ORGANIZATIONS]: { path: 'organizations' },
  [ROOMS]: { path: 'rooms' },
  [SETTINGS]: { path: 'settings' },
  [MEDIAS]: { path: 'medias' },
  [HELP]: { path: 'help' },
  [ACTIVITY_SCREENS]: {
    path: 'activity',
    screens: {
      [SCHEDULE]: { path: 'schedule' },
      [ACTIVITY]: {
        path: 'activity',
        exact: true, // Without it, the path is activity/activity
      },
    },
  },
  [PANEL]: { path: 'panel' },
};

export const publicScreen = {
  [LOGIN]: screen[LOGIN],
};

export const privateScreen = {
  [ROOMS]: screen[ROOMS],
  [HOME]: screen[HOME],
  [SETTINGS]: screen[SETTINGS],
  [MEDIAS]: screen[MEDIAS],
  [HELP]: screen[HELP],
  [LOGOUT]: screen[LOGOUT],
  [ACTIVITY_SCREENS]: screen[ACTIVITY_SCREENS],
  [PANEL]: screen[PANEL],
};

export const adminScreen = {
  [ORGANIZATIONS]: screen[ORGANIZATIONS],
  [ROOMS]: screen[ROOMS],
  [HOME]: screen[HOME],
  [SETTINGS]: screen[SETTINGS],
  [MEDIAS]: screen[MEDIAS],
  [HELP]: screen[HELP],
  [LOGOUT]: screen[LOGOUT],
};

export default {
  screenName,
  screen,
  publicScreen,
  privateScreen,
  adminScreen,
};
