import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestScheduleActivity: ['datetimes', '_room', 'externalId'],
  requestAvailableRooms: ['datetimes'],
  requestActivity: ['_id'],

  // Set reducers
  setAvailableRooms: ['rooms'],
  setActivityInfo: ['activityInfo'],

  resetActivity: [],
});

export { Types };
export default Creators;
