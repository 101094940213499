import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/device';

const initialState = fromJS({
  info: {},
  status: {
    // liveStartedAt: datetime | undefined
    // recordStartedAt: datetime | undefined
    // online: true | false | undefined
  },
});

export const setDevice = (state = initialState, { device }) => state.set('info', fromJS(device));

export const setDeviceStatus = (state = initialState, { status }) => {
  return state.mergeIn(['status'], fromJS(status));
};

export default createReducer(initialState, {
  [Types.SET_DEVICE]: setDevice,
  [Types.SET_DEVICE_STATUS]: setDeviceStatus,
});
