import { StyleSheet, View, Text, Image, Platform, FlatList } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { connect } from 'react-redux';

import colors from '../../constants/colors';
import roomSelectors from '../../App/selectors/room';
import userSelectors from '../../App/selectors/user';
import IconUser from '../../assets/user/user-placeholder.svg';

const styles = StyleSheet.create({
  container: {
    flex: 8,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
  },
  textMessage: {
    color: 'white',
    fontFamily: 'Signika',
  },
  textDatetime: {
    fontSize: 10,
    fontWeight: '300',
    fontStyle: 'normal',
    letterSpacing: 0.2,
    color: colors.getGraySantas(),
    marginLeft: 15,
  },
  textAuthor: {
    color: colors.getGraySantas(),
    marginLeft: 10,
    fontSize: 12,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0.2,
  },
  containerMessageUser: {
    marginBottom: 25,
    width: '80%',
    alignSelf: 'flex-end',
  },
  containerMessageUsers: {
    marginBottom: 25,
    width: '80%',
    alignSelf: 'flex-start',
  },
  message: {
    borderRadius: 13,
    padding: 20,
    fontSize: 16,
    fontWeight: '300',
  },
  flatList: {
    width: '100%',
  },
});

const iconWeb = {
  borderRadius: '50%',
  width: 50,
  height: 50,
};
const iconMobile = {
  borderRadius: 50,
  width: 50,
  height: 50,
};

const Messages = ({ messages, username }) => {
  const buildAvatar = (avatar) => {
    if (avatar === null || avatar === undefined) return <IconUser style={iconWeb} />;
    if (Platform.OS === 'web') return <img src={avatar} style={iconWeb} />;
    return <Image source={avatar} style={iconMobile} />;
  };

  const createMessage = (message) => {
    const { authorUsername, authorAvatar: avatar, datetime, content } = message;
    if (authorUsername === username) {
      return (
        <View key={`${authorUsername}-${datetime}`} style={styles.containerMessageUser}>
          <View style={[styles.message, { backgroundColor: '#5b41f0' }]}>
            <Text style={styles.textMessage}>{content}</Text>
          </View>

          <View style={{ marginTop: 5 }}>
            <Text style={styles.textDatetime}>{moment(datetime).fromNow()}</Text>
          </View>
        </View>
      );
    }
    return (
      <View key={`${authorUsername}-${datetime}`} style={styles.containerMessageUsers}>
        {buildAvatar(avatar)}

        <View style={{ marginLeft: 60, marginTop: -50 }}>
          <Text style={styles.textAuthor}>{authorUsername}</Text>

          <View style={[styles.message, { backgroundColor: '#212c3d' }]}>
            <Text style={styles.textMessage}>{content}</Text>
          </View>

          <View style={{ marginTop: 5 }}>
            <Text style={styles.textDatetime}>{moment(datetime).fromNow()}</Text>
          </View>
        </View>
      </View>
    );
  };

  /*
   * Current solution for messages with FlatList works, but is really not good outside of demo purpose:
   *  Init messages are "reversed" in the reducer, and on each new message, added at the start of the list...
   */
  return (
    <View style={styles.container}>
      <FlatList
        style={styles.flatList}
        data={messages}
        renderItem={({ item }) => createMessage(item)}
        keyExtractor={(item) => `${item.authorUsername}-${item.datetime}`}
        inverted
      />
    </View>
  );
};

Messages.propTypes = {
  username: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  username: userSelectors.makeSelectUsername(),
  messages: roomSelectors.makeSelectMessages(),
});

export default connect(mapStateToProps, null)(Messages);
