import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

import activitySelectors from '../../App/selectors/activity';
import organizationSelectors from '../../App/selectors/organization';
import roomSelectors from '../../App/selectors/room';
import appSelectors from '../../App/selectors/app';
import activityActions from '../../App/actions/activity';
import roomActions from '../../App/actions/room';
import ActivityPlayer from './player';

const styles = StyleSheet.create({
  formRow: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  formLabel: {
    width: '25%',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

moment.locale('fr'); // TODO: remove hard coded value...

const ActivityPage = ({
  route,
  _organization,
  room,
  activityInfo,
  currentlySending,
  requestActivity,
  requestRoom,
}) => {
  const [startTimeLeft, setStartTimeLeft] = useState(undefined);
  const [endTimeLeft, setEndTimeLeft] = useState(undefined);

  useEffect(() => {
    if (_organization) {
      if (route?.params?.id) {
        if (isEmpty(activityInfo)) requestActivity(route.params.id);
        else requestRoom(activityInfo._room);
      } // else display all activity of user?
    }
  }, [_organization]);

  useEffect(() => {
    if (!isEmpty(activityInfo)) {
      requestRoom(activityInfo._room);
      setStartTimeLeft(moment(activityInfo.startDatetime).diff(moment()));
      setEndTimeLeft(moment(activityInfo.endDatetime).diff(moment()));
    }
  }, [activityInfo]);

  useEffect(() => {
    // exit early when we reach 0
    if (startTimeLeft <= 0) return () => {};

    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setStartTimeLeft(startTimeLeft - 60000);
    }, 60000); // 60s

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add startTimeLeft as a dependency to re-rerun the effect when we update it
  }, [startTimeLeft]);

  useEffect(() => {
    // exit early when we reach 0
    if (endTimeLeft <= 0) return () => {};

    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setEndTimeLeft(endTimeLeft - 60000);
    }, 60000); // 60s

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add endTimeLeft as a dependency to re-rerun the effect when we update it
  }, [endTimeLeft]);

  const displayDatetime = () => {
    if (!isEmpty(activityInfo)) {
      const start = moment(activityInfo.startDatetime).format('lll');
      const end = moment(activityInfo.endDatetime).format('lll');
      return `${start} - ${end}`;
    }
    return null;
  };

  const startIn = () => {
    const now = moment();
    return now.to(moment(activityInfo.startDatetime));
  };

  const getActivitySummary = () => (
    <View>
      <View style={styles.formRow}>
        <Text style={styles.formLabel}>Date et heure de votre activité</Text>
        <Text>{displayDatetime()}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.formLabel}>Salle</Text>
        <Text>{room.name}</Text>
      </View>
      <View style={[styles.formRow, { marginTop: 50 }]}>
        <Text style={styles.formLabel}>Cette activité commence dans:</Text>
        <Text>{startIn()}</Text>
      </View>
    </View>
  );

  const getActivityPlayer = () => <ActivityPlayer />;

  const activitySummaryOrPlayer = () => {
    if (startTimeLeft <= 0 && endTimeLeft > 0) return getActivityPlayer();
    if (startTimeLeft <= 0 && endTimeLeft <= 0) return <Text>Activité terminée</Text>;
    return getActivitySummary();
  };

  const getDisplay = () => {
    if (currentlySending) return <ActivityIndicator size="large" style={styles.loading} />;
    if (route?.params?.id) return activitySummaryOrPlayer();
    return <Text>Vous trouverez bientôt vos activités ici.</Text>;
  };

  return getDisplay();
};

ActivityPage.propTypes = {
  route: PropTypes.object.isRequired,
  _organization: PropTypes.string,
  room: PropTypes.object,
  activityInfo: PropTypes.object.isRequired,
  currentlySending: PropTypes.bool.isRequired,

  // Actions
  requestActivity: PropTypes.func.isRequired,
  requestRoom: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  _organization: organizationSelectors.makeSelectOrganizationId(),
  room: roomSelectors.makeSelectRoom(),
  activityInfo: activitySelectors.makeSelectActivityInfo(),
  currentlySending: appSelectors.makeSelectCurrentlySending(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestActivity: (_id) => dispatch(activityActions.requestActivity(_id)),
    requestRoom: (_id) => dispatch(roomActions.requestRoom(_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPage);
