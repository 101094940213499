import React, { Component } from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';

import IconMenuKast from '../../../assets/settings/icon-menu-kastbox.svg';
import IconMenuKastActive from '../../../assets/settings/icon-menu-kastbox-active.svg';
import IconMenuHdmi from '../../../assets/settings/icon-menu-hdmi.svg';
import IconMenuHdmiActive from '../../../assets/settings/icon-menu-hdmi-active.svg';
import IconMenuPlatformsActive from '../../../assets/settings/icon-menu-active-platforms.svg';
import IconMenuPlatforms from '../../../assets/settings/icon-menu-plateformes.svg';
import IconMenuAffichage from '../../../assets/settings/icon-menu-affichage.svg';
import IconMenuAffichageActive from '../../../assets/settings/icon-menu-affichage-active.svg';

import colors from '../../../constants/colors';

import SettingAffichage from './SettingAffichageMobile';
import SettingHdmi from './SettingHdmiMobile';
import SettingMyKast from './SettingMyKastMobile';
import SettingPlatformes from './SettingPlatformesMobile';

const AFFICHAGE = 'affichage';
const HDMI = 'hdmi';
const PLATFORMES = 'platformes';
const MYKAST = 'mykast';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    flexDirection: 'row',
  },
  globalMobile: {
    flex: 12,
    flexDirection: 'column',
  },

  buttonMenu: {
    width: 170,
    height: 'auto',
    paddingHorizontal: 30,
    paddingVertical: 'auto',
    marginBottom: 30,
  },

  buttonMenuMobile: {
    flexDirection: 'row',
    height: 90,
    width: 'auto',
    paddingVertical: 'auto',
    paddingHorizontal: 'auto',
    justifyContent: 'center',
    backgroundColor: colors.getMainWhite(),
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },

  navButtons: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 'auto',
    marginVertical: 'auto',
  },
  navButtonsSelected: {
    backgroundColor: colors.getMainPurple(),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingVertical: 30,
    paddingHorizontal: 5,
    marginHorizontal: 'auto',
    marginVertical: 'auto',
  },

  menuIcon: {
    margin: 8,
    marginHorizontal: 'auto',
    marginVertical: 'auto',
  },

  menuIconSelectedMobile: {
    margin: 8,
    marginHorizontal: 'auto',
    marginVertical: 'auto',
    backgroundColor: colors.getMainPurple(),
    borderRadius: 10,
    padding: 5,
  },

  menuText: {
    margin: 8,
    color: colors.getMainBlack(),
    fontSize: 14,
    letterSpacing: -0.29,
  },
  menuSelectedText: {
    margin: 8,
    color: colors.getMainWhite(),
  },

  menuTextMobile: {
    margin: 8,
    color: colors.getMainBlack(),
    fontSize: 14,
    letterSpacing: -0.29,
  },
  menuSelectedTextMobile: {
    margin: 8,
    color: colors.getMainPurple(),
    fontSize: 14,
    letterSpacing: -0.29,
  },
});

export default class MediasMenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: AFFICHAGE,
    };
  }

  render() {
    return (
      <View style={styles.globalMobile}>
        {this.SelectedMenu()}

        <View style={styles.buttonMenuMobile}>
          <TouchableOpacity
            onPress={() => this.setState({ selectedMenu: AFFICHAGE })}
            style={styles.navButtons}
          >
            {this.displayIcon(AFFICHAGE)}
            <Text style={this.selectedText(AFFICHAGE)}>Affichage</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.setState({ selectedMenu: HDMI })}
            style={styles.navButtons}
          >
            {this.displayIcon(HDMI)}
            <Text style={this.selectedText(HDMI)}>Sortie HDMI</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.setState({ selectedMenu: PLATFORMES })}
            style={styles.navButtons}
          >
            {this.displayIcon(PLATFORMES)}
            <Text style={this.selectedText(PLATFORMES)}>Platformes</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.setState({ selectedMenu: MYKAST })}
            style={styles.navButtons}
          >
            {this.displayIcon(MYKAST)}
            <Text style={this.selectedText(MYKAST)}>My KastBox</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  selectedButton = (key) => {
    if (key === this.state.selectedMenu) return styles.menuIconSelectedMobile;
    return styles.navButtons;
  };

  selectedText = (key) => {
    if (key === this.state.selectedMenu) return styles.menuSelectedTextMobile;
    return styles.menuTextMobile;
  };

  displayIcon = (key) => {
    if (this.state.selectedMenu === key && key === AFFICHAGE) {
      return <IconMenuAffichageActive style={styles.menuIconSelectedMobile} />;
    }
    if (this.state.selectedMenu !== key && key === AFFICHAGE) {
      return <IconMenuAffichage style={styles.menuIconMobile} />;
    }
    if (this.state.selectedMenu === key && key === HDMI) {
      return <IconMenuHdmiActive style={styles.menuIconSelectedMobile} />;
    }
    if (this.state.selectedMenu !== key && key === HDMI) {
      return <IconMenuHdmi style={styles.menuIconMobile} />;
    }
    if (this.state.selectedMenu === key && key === PLATFORMES) {
      return <IconMenuPlatformsActive style={styles.menuIconSelectedMobile} />;
    }
    if (this.state.selectedMenu !== key && key === PLATFORMES) {
      return <IconMenuPlatforms style={styles.menuIconMobile} />;
    }
    if (this.state.selectedMenu === key && key === MYKAST) {
      return <IconMenuKastActive style={styles.menuIconSelectedMobile} />;
    }
    if (this.state.selectedMenu !== key && key === MYKAST) {
      return <IconMenuKast style={styles.menuIconMobile} />;
    }
    return null;
  };

  SelectedMenu = () => {
    if (this.state.selectedMenu === AFFICHAGE) return <SettingAffichage />;
    if (this.state.selectedMenu === HDMI) return <SettingHdmi />;
    if (this.state.selectedMenu === PLATFORMES) return <SettingPlatformes />;
    if (this.state.selectedMenu === MYKAST) return <SettingMyKast />;
    return null;
  };
}
