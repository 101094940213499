import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { socketMiddleware } from './socket';
import rootReducer from '../App/reducers/_index';
import sagas from '../App/sagas/_index';
import { ENV_LOCAL } from '../constants/global';

export const configureStore = () => {
  let composeEnhancers = compose;

  /* eslint-disable no-underscore-dangle */
  if (
    process.env.ENVIRONMENT === ENV_LOCAL &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  )
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  /* eslint-enable */

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, socketMiddleware()];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(rootReducer, composeEnhancers(...enhancers));

  sagaMiddleware.run(sagas);

  return store;
};

export default {
  configureStore,
};
