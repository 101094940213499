/* eslint-disable react/prop-types */
import { StyleSheet, Text, View, Switch, TouchableOpacity } from 'react-native';
import React from 'react';

import colors, { GREY, WHITE, PURPLE } from '../../../constants/colors';
import { screenName } from '../../../constants/navigation';
import { navigate } from '../../../helpers/navigation';

import IconConfig from '../../../assets/settings/icon-network.svg';

import PlateformesStreaming from './SettingPlatformesStreaming';
import PlateformesStockage from './SettingPlatformesStockage';

import ArrowIcon from '../../../assets/settings/icon-title-arrow.svg';

const styles = StyleSheet.create({
  SettingsAffichage: {
    flex: 6,
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  container: {
    flex: 10,
    marginLeft: 100,
    marginBottom: 28,
    marginRight: 41,
    paddingHorizontal: 33,
  },
  PlatFormes: {
    flexDirection: 'row',
  },
  Enregistrer: {
    flexDirection: 'row',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    height: 57,
    paddingLeft: 22,
    paddingVertical: 18,
    marginVertical: 23,
    marginRight: 41,
  },
  TextEnregitrer: {
    fontSize: 18,
    color: colors.getBluePrussian(),
    marginLeft: 10,
  },
  config: {
    flexDirection: 'row',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    paddingVertical: 19,
    paddingHorizontal: 21,
    width: 260,
  },
  ConfText: {
    fontSize: 18,
    color: colors.getMainPurple(),
    marginLeft: 19,
  },
});

export default class SettingPlatformes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: false,
    };
  }

  close = () => {
    return this.props.disableClose ? null : (
      <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 100 }}>
        <TouchableOpacity onPress={() => navigate(screenName.HOME)}>
          <Text style={{ fontSize: 20, fontWeight: '700', color: colors.getPurpleHeart() }}>
            Fermer
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.SettingsAffichage}>
        <View style={{ flex: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 100 }}>
            <Text style={{ fontSize: 35, fontWeight: '700', color: colors.getBluePrussian() }}>
              PARAMÈTRES
            </Text>
            <ArrowIcon style={{ margin: 10 }} />
            <Text style={{ fontSize: 25, fontWeight: '800', color: colors.getMountainMeadow() }}>
              Plateformes
            </Text>
          </View>
          {this.close()}
        </View>
        <View style={{ flex: 10, height: 1 }}>
          <View style={styles.container}>
            <View style={styles.PlatFormes}>
              <PlateformesStreaming />
              <PlateformesStockage />
            </View>
            <View style={styles.Enregistrer}>
              <Switch
                trackColor={{ false: GREY, true: PURPLE }}
                thumbColor={this.state.FocusValue ? WHITE : WHITE}
                ios_backgroundColor={colors.getMainWhite()}
                onValueChange={this.onSwitch}
                value={this.state.register}
              />
              <Text style={styles.TextEnregitrer}>Enregistrer automatiquement sur la caméra</Text>
            </View>
            <View style={styles.config}>
              <IconConfig />
              <Text style={styles.ConfText}>Configuration réseaux</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  onSwitch = () => {
    this.setState((prevState) => ({ register: !prevState.register }));
  };
}
