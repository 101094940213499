import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';
import roomActions, { Types as roomTypes } from '../actions/room';
import deviceActions from '../actions/device';
import organizationSelectors from '../selectors/organization';

const { REQUEST_ROOM, REQUEST_ROOM_DEVICES } = roomTypes;

function* requestRoom({ _id }) {
  const organizationId = yield select(organizationSelectors.makeSelectOrganizationId());

  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ROOM}`));

    const room = yield call(api.organization.requestRoom, organizationId, _id);

    yield put(roomActions.setRoom(room));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ROOM}`));
  }
}

function* requestRoomDevices({ _room }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ROOM_DEVICES}`));

    const devices = yield call(api.device.requestDevices, { _room });

    yield put(roomActions.setRoomDevices(devices));
    if (devices.length > 0) {
      const { liveStartedAt, recordStartedAt, online } = devices[0];
      if (liveStartedAt !== undefined || recordStartedAt !== undefined || online !== undefined) {
        delete devices[0].liveStartedAt;
        delete devices[0].recordStartedAt;
        delete devices[0].online;
        yield put(deviceActions.setDeviceStatus({ liveStartedAt, recordStartedAt, online }));
      }
      yield put(deviceActions.setDevice(devices[0]));
    }
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ROOM_DEVICES}`));
  }
}

function* watcherRequestRoom() {
  yield takeLatest(REQUEST_ROOM, requestRoom);
}

function* watcherRequestRoomDevices() {
  yield takeLatest(REQUEST_ROOM_DEVICES, requestRoomDevices);
}

export default [watcherRequestRoom(), watcherRequestRoomDevices()];
