import React, { Component } from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';

import Modal from 'modal-enhanced-react-native-web';

import IconUsb from '../../../assets/modals/icon-usb.svg';

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  containers: {
    borderRadius: 20,
    backdropFilter: 'blur(15px)',
    boxShadow: '0 0 64px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: 'rgba(245, 245, 245, 0.7)',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    padding: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    width: 400,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  TextHeader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.28,
    color: '#00245a',
  },
  loader: {
    width: 400,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleLoader: {
    height: 90,
    width: 90,
    borderRadius: '50%',
    border: 'solid 9px #ffffff',
  },
  bottom: {
    width: 400,
    marginBottom: 45,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textBottom: {
    fontSize: 14,
    letterSpacing: 0.2,
    color: '#00245a',
  },
});

export default class WebModalSoundSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleModal: null,
    };
  }

  _renderModalContent = () => (
    <View style={styles.containers}>
      <View style={styles.header}>
        <Text style={styles.TextHeader}>Ouverture</Text>
      </View>
      <View style={styles.loader}>
        <View style={styles.circleLoader}></View>
      </View>
      <View style={styles.bottom}>
        <Text style={styles.textBottom}>kalyzee-update-V5.08.11.zip</Text>
      </View>
    </View>
  );

  _handleOnScroll = (event) => {
    this.setState({
      scrollOffset: event.nativeEvent.contentOffset.y,
    });
  };

  _handleScrollTo = (p) => {
    if (this.scrollViewRef) {
      this.scrollViewRef.scrollTo(p);
    }
  };

  render() {
    return (
      <View>
        <TouchableOpacity
          style={{ marginTop: 15 }}
          onPress={() => this.setState({ visibleModal: true })}
        >
          <IconUsb />
          <Text style={{ fontSize: 12, letterSpacing: 0.17, color: '#00245a', marginTop: 10 }}>
            JARVIS
          </Text>
        </TouchableOpacity>
        <Modal
          isVisible={this.state.visibleModal}
          onBackdropPress={() => this.setState({ visibleModal: false })}
          style={styles.modal}
        >
          {this._renderModalContent()}
        </Modal>
      </View>
    );
  }
}
