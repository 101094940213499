import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import { DARK_BLUE } from '../../constants/colors';
import { USER_ROLE } from '../../constants/global';
import { screenName } from '../../constants/navigation';
import { navigate } from '../../helpers/navigation';
import { userRoleIsGTE } from '../../helpers/role';
import { useInterval } from '../../helpers/hooks';
import { sGlobal, sNavBarTop } from '../../styles/fixed';
import roomActions from '../../App/actions/room';
import roomSelectors from '../../App/selectors/room';
import userSelectors from '../../App/selectors/user';
import deviceSelectors from '../../App/selectors/device';

import IconChat from '../../assets/blue/icon-chat.svg';
import IconSettings from '../../assets/blue/icon-settings.svg';
import IconRecording from '../../assets/blue/icon-recording.svg';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: DARK_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    padding: sGlobal.padding,
    height: sNavBarTop.height,
    minHeight: sNavBarTop.height, // Otherwise won't stay fixed on resize window
    maxHeight: sNavBarTop.height, // Otherwise won't stay fixed on resize window
  },
  shortcuts: {
    position: 'absolute',
    left: 20,
    flexDirection: 'row',
    width: 120,
    justifyContent: 'space-between',
  },
  chatButton: {
    position: 'absolute',
    right: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  liveStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    backgroundColor: 'white',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const NavBarTop = (props) => {
  const [liveSince, setLiveSince] = useState(undefined);

  // TODO: Counter is "reseted" after 24h, should increment to one day and so on..
  // Use smth like this: https://stackoverflow.com/a/26050234

  useEffect(() => {
    const { deviceLiveStartedAt } = props;
    if (deviceLiveStartedAt) {
      const momentDiffMilliseconds = moment().diff(deviceLiveStartedAt);
      const momentDuration = moment.duration(momentDiffMilliseconds);
      setLiveSince(moment.utc(momentDuration.asMilliseconds()).format('HH:mm:ss'));
    } else if (deviceLiveStartedAt === undefined && liveSince !== undefined) {
      setLiveSince(undefined);
    }
  }, [props.deviceLiveStartedAt]);

  useInterval(
    () => {
      if (liveSince) {
        const momentDiffMilliseconds = moment().diff(props.deviceLiveStartedAt);
        const momentDuration = moment.duration(momentDiffMilliseconds);
        setLiveSince(moment.utc(momentDuration.asMilliseconds()).format('HH:mm:ss'));
      }
    },
    liveSince ? 1000 : null,
  );

  const iconChat = () => {
    if (!props.displayRightMenu) {
      return (
        <View style={styles.chatButton}>
          <TouchableOpacity onPress={() => props.setDisplayRightMenu(true)}>
            <IconChat />
          </TouchableOpacity>
        </View>
      );
    }
    return null;
  };

  const iconSettings = () => {
    if (!userRoleIsGTE(props.user.role, USER_ROLE.PROFESSOR)) return null;
    return (
      <TouchableOpacity onPress={() => navigate(screenName.SETTINGS)}>
        <IconSettings />
      </TouchableOpacity>
    );
  };

  // FIXME: onPress on .svg seems to create and error in console with events unhandled...
  // https://github.com/react-native-svg/react-native-svg/issues/1524

  const statusLive = useCallback(() => {
    if (liveSince) {
      return (
        <View style={styles.liveStatus}>
          <IconRecording />
          <Text style={{ marginLeft: 5 }}>{`LIVE ${liveSince}`}</Text>
        </View>
      );
    }
    return null;
  }, [liveSince]);

  return (
    <View style={styles.container}>
      <View style={styles.shortcuts}>
        {iconSettings()}
        {/* <IconMedia onClick={() => navigate(screenName.MEDIAS)} /> */}
        {/* <IconFaq onClick={() => navigate(screenName.HELP)} /> */}
      </View>
      {statusLive()}
      {iconChat()}
    </View>
  );
};

NavBarTop.propTypes = {
  displayRightMenu: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  deviceLiveStartedAt: PropTypes.string, // 'datetime' || undefined;
  // Actions
  setDisplayRightMenu: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  displayRightMenu: roomSelectors.makeSelectDisplayRightMenu(),
  user: userSelectors.makeSelectUserInfo(),
  deviceLiveStartedAt: deviceSelectors.makeSelectDeviceLiveStartedAt(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDisplayRightMenu: (bool) => dispatch(roomActions.setDisplayRightMenu(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarTop);
