import React, { Component } from 'react';
import { Text, TouchableOpacity, View, StyleSheet, TextInput, Switch } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'modal-enhanced-react-native-web';

import systemActions from '../../../App/actions/system';

import IconAdd from '../../../assets/modals/icon-add.svg';

import { GREY, PURPLE, WHITE } from '../../../constants/colors';

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  containers: {
    width: 555,
    borderRadius: 20,
    backdropFilter: 'blur(15px)',
    boxShadow: '0 0 64px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: 'rgba(245, 245, 245, 0.4)',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    padding: 23,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginBottom: 20,
    alignItems: 'center',
    border: 'solid 0.5px rgba(155, 155, 155, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  TextHeader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.28,
    color: '#04275a',
  },
  TextHeaderAnnuler: {
    marginLeft: 150,
    fontSize: 18,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: '#523bd1',
  },
  Streaming: {
    flex: 6,
    paddingHorizontal: 30,
    paddingTop: 24,
    paddingBottom: 20,
    borderRadius: 20,
    backgroundColor: '#f8f8f9',
    marginRight: 50,
  },
  TitleText: {
    fontSize: 20,
    fontWeight: 500,
    color: '#00245a',
  },
  IconStream: {
    marginHorizontal: 'auto',
    marginTop: 24,
    marginBottom: 13,
    width: 40,
    height: 40,
  },
  TextIcon: {
    fontSize: 13,
    textAlign: 'center',
    color: '#00245a',
  },
  TextIconAdd: {
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    color: '#5b41f0',
  },

  form: {
    paddingHorizontal: 25,
    paddingVertical: 35,
    position: 'flex',
    flexDirection: 'column',
  },
  texteform: {
    position: 'flex',
    flexDirection: 'row',
    marginBottom: 22,
  },
  formTextLeft: {
    fontSize: 16,
    letterSpacing: 0.22,
    color: '#04275a',
    marginRight: 200,
  },
  formTextRight: {
    fontSize: 16,
    fontweight: 600,
    letterSpacing: 0.22,
    color: '#523bd1',
  },
  input: {
    borderRadius: 13,
    border: 'solid 1px #d0d0d0',
    backgroundColor: '#ffffff',
    height: 70,
    padding: 23,
    marginBottom: 22,
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    padding: 23,
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 0.5px rgba(155, 155, 155, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
});

class WebModalRtmp extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      visibleModal: false,
      secure: false,
      Smtp: {
        Url: '',
        Cle: '',
        Nom: '',
        Protege: '',
        identifiant: '',
        mdp: '',
      },
    };

    this.state = {
      ...this.initState,
    };
  }

  updateStateNestedObj = (obj, key, val) => {
    const Smtp = { ...obj, [key]: val };
    this.setState((prevState) => ({ ...prevState, Smtp }));
  };

  updatePrevState = (obj) => {
    this.setState((prevState) => ({ ...prevState, ...obj }));
  };

  resetState = () => {
    this.setState(this.initState);
  };

  ViewSecure = () => {
    if (this.state.secure) {
      return (
        <View
          style={{
            marginTop: 40,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              letterSpacing: 0.2,
              color: '#00245a',
              marginBottom: 27,
            }}
          >
            Les visiteurs devront renseigner un identifiant et un mot de passe pour se connecter.
          </Text>
          <TextInput
            onChangeText={(val) => this.updateStateNestedObj(this.state.Smtp, 'identifiant', val)}
            style={styles.input}
            placeholder="Choisir un identifiant de connexion"
            value={this.state.Smtp.Identifiant}
          />
          <TextInput
            onChangeText={(val) => this.updateStateNestedObj(this.state.Smtp, 'mdp', val)}
            style={styles.input}
            placeholder="Choisir un mot de passe"
            value={this.state.Smtp.Mdp}
          />
        </View>
      );
    }
    return null;
  };

  _renderModalContent = () => (
    <View style={styles.containers}>
      <View style={styles.header}>
        <Text style={styles.TextHeader}>Ajouter une plateforme RTMP</Text>
        <TouchableOpacity onPress={() => this.resetState()}>
          <Text style={styles.TextHeaderAnnuler}>Annuler</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.form}>
        <View style={styles.texteform}>
          <Text style={styles.formTextLeft}>Entrez les paramètres</Text>
          <Text style={styles.formTextRight}>Besoin d’aide ?</Text>
        </View>
        <TextInput
          onChangeText={(val) => this.updateStateNestedObj(this.state.Smtp, 'Url', val)}
          style={styles.input}
          placeholder="URL du serveur"
          value={this.state.Smtp.Url}
        />
        <TextInput
          onChangeText={(val) => this.updateStateNestedObj(this.state.Smtp, 'Cle', val)}
          style={styles.input}
          placeholder="Clé de stream"
          value={this.state.Smtp.Cle}
        />
        <TextInput
          onChangeText={(val) => this.updateStateNestedObj(this.state.Smtp, 'Nom', val)}
          style={styles.input}
          placeholder="Nom"
          value={this.state.Smtp.Nom}
        />
        <Text
          style={{
            fontSize: 14,
            letterSpacing: 0.2,
            color: '#002357',
          }}
        >
          Ce nom permet de reconnaître vos différentes plateformes de streaming
        </Text>
        <View
          style={{
            marginTop: 28,
            direction: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text
            style={{
              flex: 11,
              fontSize: 16,
              letterSpacing: 0.22,
              color: '#00245a',
            }}
          >
            Protéger la diffusion
          </Text>
          <Switch
            style={{
              flex: 1,
            }}
            trackColor={{ false: GREY, true: PURPLE }}
            thumbColor={WHITE}
            ios_backgroundColor="#fefefe"
            onValueChange={() => {
              this.updatePrevState({ secure: !this.state.secure });
            }}
            value={this.state.secure}
          />
        </View>

        {this.ViewSecure()}
      </View>
      <View style={styles.button}>
        <TouchableOpacity
          onPress={() => {
            this.props.sendSmtp(this.state.Smtp);
            this.resetState();
          }}
        >
          <Text
            style={{
              fontSize: 20,
              letterSpacing: 0.28,
              color: '#523bd1',
            }}
          >
            Valider
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  render() {
    return (
      <View
        style={{
          width: 108,
          height: 108,
          margin: 15,
          borderRadius: 20,
          border: 'solid 0.5px rgba(156, 153, 177, 0.29)',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}
      >
        <TouchableOpacity onPress={() => this.updatePrevState({ visibleModal: true })}>
          <IconAdd style={styles.IconStream} />
          <Text style={styles.TextIconAdd}>Ajouter</Text>
        </TouchableOpacity>

        <Modal
          isVisible={this.state.visibleModal}
          onBackdropPress={() => this.resetState()}
          style={styles.modal}
        >
          {this._renderModalContent()}
        </Modal>
      </View>
    );
  }
}

WebModalRtmp.propTypes = {
  // Actions
  sendSmtp: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendSmtp: (object) => dispatch(systemActions.sendSmtp(object)),
  };
};

export default connect(null, mapDispatchToProps)(WebModalRtmp);
