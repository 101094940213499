import { createReducer } from 'reduxsauce';
import { fromJS } from 'immutable';

import { Types } from '../actions/room';

/**
 * {
 *  _id: '',
 *  name: '',
 *  urlPlayer: '',
 *  messages: [],
 *  users: []
 * }
 */
const initialState = fromJS({
  messages: [],
  users: [],
  displayRightMenu: false,
  devices: [],
});

export const setRoom = (state = initialState, { room }) => state.merge(fromJS(room));

// expect to have the first message be the earliest one, hence the reverse()
export const setMessages = (state = initialState, { messages }) =>
  state.set('messages', fromJS(messages.reverse()));

// setOwnMessage & setIncomingMessage are identical, but their is a distinction for the middleware sockets!
export const setOwnMessage = (state = initialState, { message }) =>
  state.update('messages', (messages) => messages.insert(0, message));

export const setIncomingMessage = (state = initialState, { message }) =>
  state.update('messages', (messages) => messages.insert(0, message));

export const setConnectedUsers = (state = initialState, { users }) =>
  state.set('users', fromJS(users));

export const setConnectedUser = (state = initialState, { user }) =>
  state.update('users', (users) => users.push(fromJS(user)));

export const removeConnectedUser = (state = initialState, { user }) =>
  state.update('users', (users) => users.filterNot((el) => el.get('_id') === user._id));

export const setDisplayRightMenu = (state = initialState, { bool }) =>
  state.set('displayRightMenu', bool);

export const setRoomDevices = (state = initialState, { devices }) =>
  state.set('devices', fromJS(devices));

export default createReducer(initialState, {
  [Types.SET_ROOM]: setRoom,
  [Types.SET_MESSAGES]: setMessages,
  [Types.SET_OWN_MESSAGE]: setOwnMessage,
  [Types.SET_INCOMING_MESSAGE]: setIncomingMessage,
  [Types.SET_CONNECTED_USERS]: setConnectedUsers,
  [Types.SET_CONNECTED_USER]: setConnectedUser,
  [Types.REMOVE_CONNECTED_USER]: removeConnectedUser,
  [Types.SET_DISPLAY_RIGHT_MENU]: setDisplayRightMenu,
  [Types.SET_ROOM_DEVICES]: setRoomDevices,
});
