import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, View } from 'react-native';
import { createStructuredSelector } from 'reselect';
import { openAuthSessionAsync } from 'expo-web-browser';

import organizationSelectors from '../../App/selectors/organization';
import userSelectors from '../../App/selectors/user';
import userActions from '../../App/actions/user';
import colors from '../../constants/colors';
import { AUTH_TYPE } from '../../constants/global';

const LogoutPage = ({ userAuthType, authMethodCas, requestLogout }) => {
  const preLogoutCas = async () => {
    const { origin, logoutPath } = authMethodCas.params;
    await openAuthSessionAsync(`${origin}${logoutPath}`);
  };

  const onLogout = () => {
    if (userAuthType === AUTH_TYPE.CAS) requestLogout(preLogoutCas);
    else requestLogout();
  };

  return (
    <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: 300, height: 100 }}>
        <Button title="Logout" color={colors.getMainGreen()} onPress={onLogout} />
      </View>
    </View>
  );
};

LogoutPage.propTypes = {
  userAuthType: PropTypes.string,
  authMethodCas: PropTypes.object,

  requestLogout: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userAuthType: userSelectors.makeSelectUserAuthType(),
  authMethodCas: organizationSelectors.makeSelectAuthMethodCas(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestLogout: (preLogout, postLogout) =>
      dispatch(userActions.requestLogout(preLogout, postLogout)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
