import { StyleSheet, Text, View, Switch } from 'react-native';
import React from 'react';

import colors, { GREY, WHITE, PURPLE } from '../../../constants/colors';

import IconConfig from '../../../assets/settings/icon-network.svg';

import PlateformesStreaming from './SettingPlatformesStreamingMobile';
import PlateformesStockage from './SettingPlatformesStockageMobile';

const styles = StyleSheet.create({
  SettingsAffichage: {
    flex: 6,
    backgroundColor: colors.getMainWhite(),
    flexDirection: 'column',
  },
  container: {
    flex: 12,
    marginTop: 30,
    marginHorizontal: 16,
  },
  PlatFormes: {
    flexDirection: 'column',
    flex: 8,
  },
  Enregistrer: {
    flexDirection: 'row',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    height: 57,
    paddingLeft: 22,
    paddingVertical: 18,
    marginVertical: 23,
    marginHorizontal: 'auto',
  },
  TextEnregitrer: {
    fontSize: 15,
    color: colors.getBluePrussian(),
    marginLeft: 10,
  },
  config: {
    flexDirection: 'row',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    paddingVertical: 19,
    paddingHorizontal: 21,
    width: 260,
  },
  ConfText: {
    fontSize: 18,
    color: colors.getMainPurple(),
    marginLeft: 19,
  },
});

export default class SettingPlatformes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: false,
    };
  }

  render() {
    return (
      <View style={styles.SettingsAffichage}>
        <View style={{ flex: 10, height: 1 }}>
          <View style={styles.container}>
            <View style={styles.PlatFormes}>
              <PlateformesStreaming />
              <PlateformesStockage />
            </View>
            <View style={styles.Enregistrer}>
              <Switch
                trackColor={{ false: GREY, true: PURPLE }}
                thumbColor={this.state.FocusValue ? WHITE : WHITE}
                ios_backgroundColor={colors.getMainWhite()}
                onValueChange={this.onSwitch}
                value={this.state.register}
              />
              <Text style={styles.TextEnregitrer}>Enregistrer automatiquement sur la caméra</Text>
            </View>
            <View style={styles.config}>
              <IconConfig />
              <Text style={styles.ConfText}>Configuration réseaux</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  onSwitch = () => {
    this.setState((prevState) => ({ register: !prevState.register }));
  };
}
