import { Platform, StyleSheet, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NavBarTop from '../../containers/NavBarTop';
import NavigationMenu from './SettingView/SettingNavigation';
import NavigationMenuMobile from './SettingView/SettingNavigationMobile';
import roomSelectors from '../../App/selectors/room';
import deviceSelectors from '../../App/selectors/device';
import { getDomain, navigate } from '../../helpers/navigation';

import { DARK_BLUE } from '../../constants/colors';
import { screenName } from '../../constants/navigation';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    backgroundColor: DARK_BLUE,
  },
  container: {
    flex: 12,
    width: '100%',
    backgroundColor: 'rgba(235, 235, 235, 1)',
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
  },
  main: {
    flex: 1,
  },
  layer: {
    zIndex: 2,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

const LARGE = 'large';
const MIN = 'min';

// TODO: There is some hardcoded line break...
// TODO: Remove FlatList from SettingPlatform mobile
// TODO: On resize from web to mobile (and vice versa), keep the current selected "tab"

const Setting = ({ room, device, hideNavBarTop, disableClose }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth >= 1200 ? LARGE : MIN);

  const [prevScreenWidth, setPrevScreenWidth] = useState(window.innerWidth >= 1200 ? LARGE : MIN);

  const handleResize = () => {
    setScreenWidth(window.innerWidth >= 1200 ? LARGE : MIN);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (room._id === undefined && device._id === undefined) navigate(screenName.ROOMS);
  });

  const getNavigationForWeb = () => {
    if (screenWidth !== prevScreenWidth) {
      if (screenWidth === LARGE) {
        setPrevScreenWidth(LARGE);
        return <NavigationMenu disableClose={disableClose} />;
      }
      setPrevScreenWidth(MIN);
      return <NavigationMenuMobile />;
    }
    return prevScreenWidth === LARGE ? (
      <NavigationMenu disableClose={disableClose} />
    ) : (
      <NavigationMenuMobile />
    );
  };

  const loadNavigation = () => {
    if (Platform.OS !== 'web') return <NavigationMenuMobile />;
    return getNavigationForWeb();
  };

  const getBody = () => {
    if (getDomain().includes('kalyzee')) {
      return (
        <View style={styles.global}>
          <View style={styles.main}>
            {hideNavBarTop ? null : <NavBarTop />}
            <View style={styles.container}>{loadNavigation()}</View>
          </View>
        </View>
      );
    }
    return (
      <View style={styles.global}>
        <View style={styles.layer}>A VENIR</View>
        <View style={[styles.main, { position: 'absolute', width: '100%', height: '100%' }]}>
          {hideNavBarTop ? null : <NavBarTop />}
          <View style={styles.container}>{loadNavigation()}</View>
        </View>
      </View>
    );
  };

  return getBody();
};

Setting.propTypes = {
  room: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  hideNavBarTop: PropTypes.bool,
  disableClose: PropTypes.bool,
};

Setting.defaultProps = {
  hideNavBarTop: false,
  disableClose: false,
};

const mapStateToProps = createStructuredSelector({
  room: roomSelectors.makeSelectRoom(),
  device: deviceSelectors.makeSelectDeviceInfo(),
});

export default connect(mapStateToProps, null)(Setting);
