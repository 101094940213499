import { ENV_LOCAL, ENV_STAGING, ENV_PROD } from '../constants/global';

// Source: react-boilerplate => https://github.com/react-boilerplate/react-boilerplate  (app/utils/request.js)

/**
 * Get the current backend base (root) url depending on env variable
 *
 * @returns {string} Returns a url as string
 */
const getBaseUrl = () => {
  if (process.env.ENVIRONMENT === ENV_LOCAL) return 'https://api.local.kast.app';
  if (process.env.ENVIRONMENT === ENV_STAGING) return 'https://api.staging.kast.app';
  if (process.env.ENVIRONMENT === ENV_PROD) return 'https://api.kast.app';
  return '';
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

const buildQueryParams = (params) => {
  const encodeKeyVal = (key, val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key]))
        return params[key].map((val) => encodeKeyVal(key, val).join('&'));
      return encodeKeyVal(key, params[key]);
    })
    .join('&');
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
const request = (url, options) => {
  return fetch(url, options).then(checkStatus).then(parseJSON);
};

/**
 * Request an URL and return a promise
 *
 * @param {string} path The URL to request
 * @param {object} options Options to use for the request
 * @param {object} [queryParams] Query Params to add the request url (handle array params)
 *
 * @returns {object} Promise with the response data
 */
export const requestAPI = (path, options, queryParams = {}) => {
  let url = `${getBaseUrl()}${path}`;
  if (queryParams && Object.keys(queryParams).length > 0 && queryParams.constructor === Object)
    url += `?${buildQueryParams(queryParams)}`;
  return request(url, options);
};

export default {
  requestAPI,
};
