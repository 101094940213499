import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import { createStructuredSelector } from 'reselect';

import deviceActions from '../../App/actions/device';
import { DARK_BLUE } from '../../constants/colors';
import { sNavBarTop, sGlobal, sVideoControlRow } from '../../styles/fixed';
import deviceSelectors from '../../App/selectors/device';

import Hoverable from '../../components/Hoverable';

import CameraControl from './cameraControl';
import VideoControl from './videoControl';

const styles = StyleSheet.create({
  topContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: DARK_BLUE,
    justifyContent: 'center',
    height: '100%',
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: sVideoControlRow.height,
  },
  hoverableView: {
    maxHeight: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Player = ({
  source,
  controls,
  muted,
  autoPlay,
  hasControlRights,
  deviceStatus,
  move,
  stopMove,
}) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const window = useWindowDimensions();

  const isHLS = source.endsWith('.m3u8');

  const refVideo = useRef(null);

  useEffect(() => {
    if (refVideo !== null && refVideo?.current !== null && source !== undefined && isHLS) {
      const { current: player } = refVideo;
      if (player.canPlayType('application/vnd.apple.mpegurl')) {
        // If HLS is natively supported, let the browser do the work!
        player.src = source;
        player.addEventListener('loadedmetadata', () => player.play());
      } else if (Hls.isSupported()) {
        // If the browser supports MSE, use hls.js to play the video
        const hls = new Hls({});
        hls.loadSource(source);
        hls.attachMedia(player);
        hls.on(Hls.Events.MANIFEST_PARSED, () => player.play());
      } else alert('Please use a modern browser to play the video');
    }
  }, [refVideo, source]);

  useEffect(() => {
    const height = window.height - sNavBarTop.height;
    const paddingHeight = hasControlRights && deviceStatus.online ? 0 : sGlobal.padding;
    setMaxHeight(height - paddingHeight);
  }, [window]);

  const getVideo = () => {
    // That's a html balise, so StyleSheet does not work!
    // DO NOT SET the width! Otherwise it will brake the UI for the CameraControl
    // (width and height are calculed automaticaly, but we need an init height data (video height is always the smaller value, so we use it))
    return (
      <video
        ref={refVideo}
        style={{
          objectFit: 'contain',
          maxHeight: '100%',
          maxWidth: '100%',
          height: '100%',
        }}
        controls={controls}
        muted={muted}
        autoPlay={autoPlay}
      />
    );
  };

  const getIframe = () => {
    return (
      <iframe
        style={{
          objectFit: 'contain',
          maxHeight: '100%',
          maxWidth: '100%',
          height: '100%',
          width: '100%',
        }}
        src={source}
        frameBorder="0"
        allowFullScreen
      />
    );
  };

  const getHoverableTopContainerStyle = () => {
    return [
      styles.container,
      {
        maxHeight:
          hasControlRights && deviceStatus.online ? maxHeight - sVideoControlRow.height : maxHeight,
      },
    ];
  };

  const getControlTopViewStyle = () => {
    return [
      styles.controls,
      { display: hasControlRights && deviceStatus.online ? 'flex' : 'none' },
    ];
  };

  return (
    <View style={[styles.topContainer, { maxHeight, minHeight: maxHeight }]}>
      <View style={getHoverableTopContainerStyle()}>
        <Hoverable>
          {(isHovered) => (
            <View style={styles.hoverableView}>
              {isHLS ? getVideo() : getIframe()}
              {isHovered && deviceStatus.liveStartedAt !== undefined && (
                <CameraControl
                  move={move}
                  stopMove={stopMove}
                  display={deviceStatus.online}
                  isIframe={!isHLS}
                />
              )}
            </View>
          )}
        </Hoverable>
      </View>

      <View style={getControlTopViewStyle()}>
        <VideoControl />
      </View>
    </View>
  );
};

Player.propTypes = {
  source: PropTypes.string,
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  hasControlRights: PropTypes.bool,
  deviceStatus: PropTypes.object.isRequired,
  move: PropTypes.func.isRequired,
  stopMove: PropTypes.func.isRequired,
};

Player.defaultProps = {
  source: '',
  controls: false,
  muted: false,
  autoPlay: false,
  hasControlRights: false,
};

const mapStateToProps = createStructuredSelector({
  deviceStatus: deviceSelectors.makeSelectDeviceStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    move: (direction) => dispatch(deviceActions.move(direction)),
    stopMove: () => dispatch(deviceActions.stopMove()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
