import React, { Component } from 'react';
import { Text, TouchableOpacity, View, StyleSheet, TextInput } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'modal-enhanced-react-native-web';

import systemActions from '../../../App/actions/system';

import IconAdd from '../../../assets/modals/icon-add.svg';

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  containers: {
    width: 555,
    borderRadius: 20,
    backdropFilter: 'blur(15px)',
    boxShadow: '0 0 64px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: 'rgba(245, 245, 245, 0.4)',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    padding: 23,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginBottom: 20,
    alignItems: 'center',
    border: 'solid 0.5px rgba(155, 155, 155, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  TextHeader: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.28,
    color: '#04275a',
  },
  TextHeaderAnnuler: {
    marginLeft: 180,
    fontSize: 18,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: '#523bd1',
  },
  Streaming: {
    flex: 6,
    paddingHorizontal: 30,
    paddingTop: 24,
    paddingBottom: 20,
    borderRadius: 20,
    backgroundColor: '#f8f8f9',
    marginRight: 50,
  },
  TitleText: {
    fontSize: 20,
    fontWeight: 500,
    color: '#00245a',
  },
  IconStream: {
    marginHorizontal: 'auto',
    marginTop: 24,
    marginBottom: 13,
    width: 40,
    height: 40,
  },
  TextIcon: {
    fontSize: 13,
    textAlign: 'center',
    color: '#00245a',
  },
  TextIconAdd: {
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    color: '#5b41f0',
  },

  form: {
    paddingHorizontal: 25,
    paddingVertical: 35,
    position: 'flex',
    flexDirection: 'column',
  },
  texteform: {
    position: 'flex',
    flexDirection: 'row',
    marginBottom: 22,
  },
  formTextLeft: {
    fontSize: 16,
    letterSpacing: 0.22,
    color: '#04275a',
    marginRight: 200,
  },
  formTextRight: {
    fontSize: 16,
    fontweight: 600,
    letterSpacing: 0.22,
    color: '#523bd1',
  },
  input: {
    borderRadius: 13,
    border: 'solid 1px #d0d0d0',
    backgroundColor: '#ffffff',
    height: 70,
    padding: 23,
    marginBottom: 22,
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    padding: 23,
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 0.5px rgba(155, 155, 155, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
});

class WebModalFtp extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      visibleModal: false,
      ftp: {
        Host: '',
        Username: '',
        Password: '',
        Port: '',
        Nom: '',
      },
    };

    this.state = {
      ...this.initState,
    };
  }

  resetState = () => {
    this.setState(this.initState);
  };

  updateStateNestedObj = (obj, key, val) => {
    const ftp = { ...obj, [key]: val };
    this.setState((prevState) => ({ ...prevState, ftp }));
  };

  updatePrevState = (obj) => {
    this.setState((prevState) => ({ ...prevState, ...obj }));
  };

  _renderModalContent = () => (
    <View style={styles.containers}>
      <View style={styles.header}>
        <Text style={styles.TextHeader}>Ajouter une plateforme FTP</Text>
        <TouchableOpacity onPress={() => this.resetState()}>
          <Text style={styles.TextHeaderAnnuler}>Annuler</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.form}>
        <View style={styles.texteform}>
          <Text style={styles.formTextLeft}>Entrez les paramètres</Text>
          <Text style={styles.formTextRight}>Besoin d’aide ?</Text>
        </View>
        <TextInput
          onChangeText={(val) => this.updateStateNestedObj(this.state.ftp, 'Host', val)}
          style={styles.input}
          placeholder="Host"
          value={this.state.ftp.Host}
        />
        <TextInput
          onChangeText={(val) => this.updateStateNestedObj(this.state.ftp, 'Username', val)}
          style={styles.input}
          placeholder="Username"
          value={this.state.ftp.Username}
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <TextInput
            style={{
              borderRadius: 13,
              border: 'solid 1px #d0d0d0',
              backgroundColor: '#ffffff',
              height: 70,
              padding: 23,
              marginBottom: 22,
              marginRight: 22,
              flex: 8,
            }}
            onChangeText={(val) => this.updateStateNestedObj(this.state.ftp, 'Password', val)}
            placeholder="Password"
            secureTextEntry={true}
            value={this.state.ftp.Password}
          />
          <TextInput
            onChangeText={(val) => this.updateStateNestedObj(this.state.ftp, 'Port', val)}
            style={styles.input}
            placeholder="Port"
            value={this.state.ftp.Port}
          />
        </View>
        <TextInput
          onChangeText={(val) => this.updateStateNestedObj(this.state.ftp, 'Nom', val)}
          style={styles.input}
          placeholder="Nom"
          value={this.state.ftp.Nom}
        />
        <Text
          style={{
            fontSize: 14,
            letterSpacing: 0.2,
            color: '#002357',
          }}
        >
          Ce nom permet de reconnaître vos différentes plateformes de streaming
        </Text>
      </View>
      <View style={styles.button}>
        <TouchableOpacity
          onPress={() => {
            this.props.sendFtp(this.state.ftp);
            this.resetState();
          }}
        >
          <Text
            style={{
              fontSize: 20,
              letterSpacing: 0.28,
              color: '#523bd1',
            }}
          >
            Valider
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  render() {
    return (
      <View
        style={{
          width: 108,
          height: 108,
          margin: 15,
          borderRadius: 20,
          border: 'solid 0.5px rgba(156, 153, 177, 0.29)',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}
      >
        <TouchableOpacity onPress={() => this.updatePrevState({ visibleModal: true })}>
          <IconAdd style={styles.IconStream} />
          <Text style={styles.TextIconAdd}>Ajouter</Text>
        </TouchableOpacity>

        <Modal
          isVisible={this.state.visibleModal}
          onBackdropPress={() => this.resetState()}
          style={styles.modal}
        >
          {this._renderModalContent()}
        </Modal>
      </View>
    );
  }
}

WebModalFtp.propTypes = {
  // Actions
  sendFtp: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendFtp: (object) => dispatch(systemActions.sendFtp(object)),
  };
};

export default connect(null, mapDispatchToProps)(WebModalFtp);
