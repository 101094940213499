/* eslint-disable react/prop-types */
import { StyleSheet, Text, View, Platform, TouchableOpacity, Image } from 'react-native';
import React from 'react';

import { navigate } from '../../../helpers/navigation';
import { screenName } from '../../../constants/navigation';

import IconMajCamera from '../../../assets/settings/icon-update-camera.svg';
import IconArrow from '../../../assets/blue/icon-right.svg';

import ArrowIcon from '../../../assets/settings/icon-title-arrow.svg';

import WebModalUpdateApp from '../modals/WebModalUpdateApp';
import colors from '../../../constants/colors';

const IconMajApp = require('../../../assets/settings/icon-update-kalyzee@3x.jpg');
const IconKalyzee = require('../../../assets/logo/icon-logo.png');

const styles = StyleSheet.create({
  SettingsMyKast: {
    flex: 6,
    backgroundColor: 'white',
    flexDirection: 'column',
  },

  BtnMajApp: {
    position: 'absolute',
    right: 14,
    borderRadius: 11,
    backgroundColor: colors.getBlueCornflower(),
    paddingHorizontal: 20,
    paddingVertical: 9,
  },
  TextMajAppBtn: {
    fontSize: 16,
    letterSpacing: 0.22,
    color: colors.getMainWhite(),
  },
  Maj: {
    flexDirection: 'row',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    paddingHorizontal: 14,
    paddingVertical: 11,
    alignItems: 'center',
    marginBottom: 15,
  },
  TextMajApp: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.27,
    color: colors.getBluePrussian(),
    marginLeft: 17,
  },
  Puce: {
    borderRadius: 50,
    width: 22.5,
    height: 22.5,
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: colors.getMainRed(),
    paddingVertical: 3,
    marginLeft: 10,
  },
  TextPuce: {
    color: 'white',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.22,
  },
  MajCam: {
    flexDirection: 'column',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    paddingHorizontal: 14,
    paddingVertical: 11,
    alignItems: 'center',
    marginBottom: 15,
  },
  HeaderMaj: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  TextExplication: {
    marginTop: 16,
    width: '100%',
  },
  TextExplicationHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.22,
    color: colors.getBluePrussian(),
  },
  TextExplicationBody: {
    marginTop: 20,
    fontSize: 14,
    lineHeight: 1.86,
    letterSpacing: 0.19,
    color: colors.getBluePrussian(),
  },
  TextExplicationBtn: {
    paddingVertical: 15,
    paddingHorizontal: 12,
    borderRadius: 15,
    backgroundColor: 'rgba(91, 65, 240, 0.1)',
    marginTop: 20,
    width: 190,
  },
  CleUsbContainer: {
    marginTop: 35,
    width: '100%',
  },
  TextCleUsb: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.86,
    letterSpacing: 0.19,
    color: colors.getBluePrussian(),
  },
  line: {
    height: 1,
    border: `solid 0.5px ${colors.getGrayDusty()}`,
  },
  VideoTutoriel: {
    width: 252,
    flexDirection: 'row',
    paddingVertical: 11,
    paddingLeft: 12,
    paddingRight: 40,
    borderRadius: 10,
    backgroundColor: colors.getMainWhite(),
    alignItems: 'center',
  },
  TextTutoriel: {
    marginLeft: 20,
    fontSize: 16,
    letterSpacing: 0.22,
    color: colors.getBluePrussian(),
  },
  MyKastBox: {
    flexDirection: 'column',
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    paddingLeft: 40,
    paddingTop: 24,
    paddingBottom: 39,
  },
});

export default class SettingMyKast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deplier: false,
    };
  }

  close = () => {
    return this.props.disableClose ? null : (
      <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 100 }}>
        <TouchableOpacity onPress={() => navigate(screenName.HOME)}>
          <Text style={{ fontSize: 20, fontWeight: '700', color: colors.getPurpleHeart() }}>
            Fermer
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.SettingsMyKast}>
        <View
          style={{
            flex: 2,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 24,
            marginTop: 51,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 100 }}>
            <Text style={{ fontSize: 35, fontWeight: '700', color: colors.getBluePrussian() }}>
              PARAMÈTRES
            </Text>
            <ArrowIcon style={{ margin: 10 }} />
            <Text style={{ fontSize: 25, fontWeight: '800', color: colors.getMountainMeadow() }}>
              My KastBox
            </Text>
          </View>
          {this.close()}
        </View>
        <View
          style={{ flex: 10, height: 1, flexDirection: 'column', marginLeft: 100, marginRight: 40 }}
        >
          <View style={styles.Maj}>
            {this.getPicture()}
            <Text style={styles.TextMajApp}>Mise à jour de l’application Kalyzée</Text>
            <View style={styles.Puce}>
              <Text style={styles.TextPuce}>1</Text>
            </View>
            <TouchableOpacity
              style={styles.BtnMajApp}
              onPress={() => this.setState({ visibleModal: true })}
            >
              <Text style={styles.TextMajAppBtn}>Mettre à jour</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.MajCam}>
            <View style={styles.HeaderMaj}>
              <IconMajCamera style={styles.IconMajApp} />
              <Text style={styles.TextMajApp}>Mise à jour logiciel caméra</Text>
              <View style={styles.Puce}>
                <Text style={styles.TextPuce}>1</Text>
              </View>
              {this.getArrow()}
            </View>
            {this.DisplayAll()}
          </View>
          <View style={styles.MyKastBox}>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  width: 149.7,
                  height: 149.7,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {this.getLogo()}
              </View>
              <View style={{ flexDirection: 'column', marginLeft: 38, paddingTop: 30 }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    letterSpacing: 0.22,
                    color: colors.getBluePrussian(),
                  }}
                >
                  My Kast Box
                </Text>
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      letterSpacing: 0.22,
                      color: colors.getBluePrussian(),
                    }}
                  >
                    Kast version :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 30,
                      fontSize: 16,
                      letterspacing: 0.22,
                      color: colors.getBluePrussian(),
                    }}
                  >
                    2020-08-17_22:52
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      letterSpacing: 0.22,
                      color: colors.getBluePrussian(),
                    }}
                  >
                    Architecture :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 30,
                      fontSize: 16,
                      letterspacing: 0.22,
                      color: colors.getBluePrussian(),
                    }}
                  >
                    armeabi-v7a
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                position: 'absolute',
                right: 18,
                bottom: 17,
                paddingVertical: 9,
                paddingHorizontal: 18,
                borderRadius: 11,
                backgroundColor: colors.getBlueCornflower(),
              }}
            >
              <Text style={{ fontSize: 16, letterSpacing: 0.22, color: colors.getMainWhite() }}>
                Diagnostic système
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 21, justifyContent: 'right' }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
                letterSpacing: 0.22,
                color: colors.getBlueCornflower(),
              }}
            >
              Factory reset
            </Text>
          </View>
        </View>
      </View>
    );
  }

  getModalUpdate = () => <WebModalUpdateApp />;

  getPicture = () => {
    if (Platform.OS === 'web') {
      return <img src={IconMajApp} style={{ width: 50, height: 50 }} />;
    }
    return <Image source={IconMajApp} style={{ width: 50, height: 50 }} />;
  };

  getLogo = () => {
    if (Platform.OS === 'web') {
      return <img src={IconKalyzee} style={{ width: 100, height: 100 }} />;
    }
    return <Image source={IconKalyzee} style={{ width: 100, height: 100 }} />;
  };

  getArrow = () => {
    if (this.state.deplier) {
      return (
        <IconArrow
          style={{ position: 'absolute', right: 20, transform: [{ rotateZ: '-90deg' }] }}
          onClick={() => {
            this.setState((prevState) => ({ deplier: !prevState.deplier }));
          }}
        />
      );
    }
    return (
      <IconArrow
        style={{ position: 'absolute', right: 20 }}
        onClick={() => {
          this.setState((prevState) => ({ deplier: !prevState.deplier }));
        }}
      />
    );
  };

  DisplayAll = () => {
    return this.state.deplier ? (
      <View style={{ flexDirection: 'column', width: '100%', paddingLeft: 70 }}>
        <View style={styles.TextExplication}>
          <Text style={styles.TextExplicationHeader}>
            Une mise à jour est disponible : comment procéder ?
          </Text>
          <Text style={styles.TextExplicationBody}>
            {`1. Demander à l'équipe support le fichier de mise à jour (au format .zip)`}
          </Text>
          <View>
            <View style={styles.TextExplicationBtn}>
              <Text
                style={{
                  ontSize: 16,
                  fontWeight: 600,
                  letterSpacing: 0.22,
                  textAlign: 'center',
                  color: colors.getMainPurple(),
                }}
              >
                Contacter le support
              </Text>
            </View>
          </View>
          <Text style={styles.TextExplicationBody}>
            2. Le copier sur une clé USB (ne pas décompresser le fichier).
          </Text>
          <Text style={styles.TextExplicationBody}>3. Insérer la clé USB sur la caméra</Text>
          <Text style={styles.TextExplicationBody}>
            4. Une fois la clé USB branchée sur la caméra, sélectionner ici le fichier .zip sur
            celle-ci pour installer la mise à jour.
          </Text>
        </View>
        <View style={styles.CleUsbContainer}>
          <Text style={styles.TextCleUsb}>Clé détectée :</Text>
          {this.getModalUpdate()}
        </View>
        <View style={{ paddingRight: 30, width: '100%', marginTop: 18 }}>
          <View style={styles.line}></View>
        </View>
        <View style={{ width: '100%', marginTop: 34 }}>
          <View style={styles.VideoTutoriel}>
            <View
              style={{ width: 57, height: 52, borderRadius: 17, backgroundColor: 'black' }}
            ></View>
            <Text style={styles.TextTutoriel}>Voir le tutoriel</Text>
          </View>
        </View>
      </View>
    ) : null;
  };
}
