import { createSelector } from 'reselect';

const systemSelector = (globalState) => globalState.get('system');
const videoSelector = (state) => systemSelector(state).get('video');
const audioSelector = (state) => systemSelector(state).get('audio');

// Video Selectors
const flipSelector = (state) => videoSelector(state).get('flip');

const makeSelectVideo = () => createSelector(videoSelector, (state) => state.toJS());

const makeSelectAudio = () => createSelector(audioSelector, (state) => state.toJS());

const makeSelectFlip = () => createSelector(flipSelector, (state) => state);

export default {
  makeSelectVideo,
  makeSelectAudio,
  makeSelectFlip,
};
