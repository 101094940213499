import { fromJS } from 'immutable';
import { createReducer } from 'reduxsauce';

import { Types } from '../actions/app';

const initialState = fromJS({
  currentlySending: [], // Store the action needing a "loading" state
});

export const addCurrentlySending = (state = initialState, { name }) =>
  state.update('currentlySending', (currentlySending) => currentlySending.push(name));

export const removeCurrentlySending = (state = initialState, { name }) =>
  state.update('currentlySending', (currentlySending) => {
    const index = currentlySending.findIndex((el) => el === name);
    return currentlySending.delete(index);
  });

export default createReducer(initialState, {
  [Types.ADD_CURRENTLY_SENDING]: addCurrentlySending,
  [Types.REMOVE_CURRENTLY_SENDING]: removeCurrentlySending,
});
