import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';
import activityActions, { Types as activityTypes } from '../actions/activity';
import { navigate } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';

const { REQUEST_SCHEDULE_ACTIVITY, REQUEST_AVAILABLE_ROOMS, REQUEST_ACTIVITY } = activityTypes;

/**
 * TODO: In the future. Improvement
 * Should be done by socket.io, to avoid conflict if two user are on the same datetimes + room
 * Edit button
 * OnEdit remove _room from activity database, redirect to /activity/schedule?edit=${activity._id} + PATCH
 *
 */

function* requestScheduleActivity({ datetimes, _room, externalId }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_SCHEDULE_ACTIVITY}`));
    const body = {
      startDatetime: datetimes[0],
      endDatetime: datetimes[1],
      _room,
    };
    if (externalId !== undefined) body.externalId = externalId;
    const activity = yield call(api.activity.requestScheduleActivity, body);

    yield put(activityActions.setActivityInfo(activity));

    navigate(screenName.ACTIVITY, { id: activity._id });
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_SCHEDULE_ACTIVITY}`));
  }
}

function* requestAvailableRooms({ datetimes }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_AVAILABLE_ROOMS}`));

    const rooms = yield call(api.activity.requestAvailableRooms, {
      startDatetime: datetimes[0],
      endDatetime: datetimes[1],
    });

    yield put(activityActions.setAvailableRooms(rooms));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_AVAILABLE_ROOMS}`));
  }
}

function* requestActivity({ _id }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_ACTIVITY}`));

    const activity = yield call(api.activity.requestActivity, _id);

    yield put(activityActions.setActivityInfo(activity));
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_ACTIVITY}`));
  }
}

function* watcherRequestScheduleActivity() {
  yield takeLatest(REQUEST_SCHEDULE_ACTIVITY, requestScheduleActivity);
}

function* watcherRequestAvailableRooms() {
  yield takeLatest(REQUEST_AVAILABLE_ROOMS, requestAvailableRooms);
}

function* watcherRequestActivity() {
  yield takeLatest(REQUEST_ACTIVITY, requestActivity);
}

export default [
  watcherRequestScheduleActivity(),
  watcherRequestAvailableRooms(),
  watcherRequestActivity(),
];
