import platformHelpers from './platform';

export const setToken = (token) => platformHelpers.storageSetter('token', token);

export const getToken = () => platformHelpers.storageGetter('token');

export const removeToken = () => platformHelpers.storageRemover('token');

/**
 * Get the JWT Token and return it has an HTTP Header
 *
 * @returns {object} Returns { Authorization: `Bearer ${token}` } or empty Object
 */
export const getAuthHeader = () => {
  const token = getToken();

  if (token) return { Authorization: `Bearer ${token}` };
  return {};
};

export default {
  setToken,
  getToken,
  removeToken,
  getAuthHeader,
};
