import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Text, Button, View } from 'react-native';

import colors, { DARK_BLUE } from '../../constants/colors';
import { screenName } from '../../constants/navigation';
import { navigate } from '../../helpers/navigation';
import userActions from '../../App/actions/user';
import userSelectors from '../../App/selectors/user';
import appSelectors from '../../App/selectors/app';
import organizationActions from '../../App/actions/organization';

const OrganizationPage = (props) => {
  useEffect(() => {
    props.requestOrganizations();
  }, []);

  const handleSelectOrganization = (organization) => {
    props.setOrganization(organization);
    navigate(screenName.ROOMS);
  };

  const buildRows = (maxByRow) => {
    const rows = [];
    let currRow = [];
    const pushRow = (organization, button) => {
      rows.push(
        <View
          key={`row-${organization._id}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            flexWrap: 'nowrap',
            alignContent: 'center',
          }}
        >
          {button}
        </View>,
      );
    };
    props.organizations.forEach((organization, index) => {
      const button = (
        <Button
          key={organization._id}
          title={organization.name}
          onPress={() => handleSelectOrganization(organization)}
          color={colors.getMainGreen()}
        />
      );

      currRow.push(button);

      if (!((index + 1) % maxByRow)) {
        pushRow(organization, currRow);
        currRow = [];
      }
      if (index + 1 === props.organizations.length && currRow.length >= 1)
        pushRow(organization, currRow);
      return button;
    });
    return rows;
  };

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundColor: DARK_BLUE,
      }}
    >
      <View
        style={{
          width: '75%',
          height: '75%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'stretch',
        }}
      >
        {props.currentlySending ? <Text>Loading...</Text> : buildRows(3)}
      </View>
    </View>
  );
};

OrganizationPage.propTypes = {
  organizations: PropTypes.array.isRequired,
  currentlySending: PropTypes.bool.isRequired,

  // Actions
  requestOrganizations: PropTypes.func.isRequired,
  setOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  organizations: userSelectors.makeSelectOrganizations(),
  currentlySending: appSelectors.makeSelectCurrentlySending(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestOrganizations: () => dispatch(userActions.requestOrganizations()),
    setOrganization: (organization) => dispatch(organizationActions.setOrganization(organization)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPage);
