import { StyleSheet, View, Text, ActivityIndicator } from 'react-native';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import colors from '../../constants/colors';
import { AUTH_TYPE } from '../../constants/global';

import userActions, { Types as userTypes } from '../../App/actions/user';
import userSelectors from '../../App/selectors/user';
import organizationSelectors from '../../App/selectors/organization';
import organizationActions from '../../App/actions/organization';
import appSelectors from '../../App/selectors/app';
import LoginCAS from './LoginCAS';
import LoginLocal from './LoginLocal';

import { setToken } from '../../helpers/auth';
import { getOrigin } from '../../helpers/navigation';
import { screenName } from '../../constants/navigation';

const { REQUEST_LOGIN_LOCAL, REQUEST_LOGIN_CAS } = userTypes;
const { LOCAL, CAS } = AUTH_TYPE;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'stretch',
    flex: 1,
    backgroundColor: 'white',
  },
  formContainer: {
    width: 444,
    borderRadius: 20,
    backdropFilter: 'blur(45px)',
    border: `solid 0.5px ${colors.getGraySantas(0.2)}`,
    backgroundColor: colors.getGrayAthens(),
    paddingHorizontal: 30,
    paddingVertical: 33,
  },
  cover: {
    flex: 1,
    backgroundColor: colors.getMirage(),
  },
  form: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 29,
    fontWeight: 900,
    textAlign: 'center',
    color: colors.getMainGreen(),
    marginBottom: 50,
    alignSelf: 'center',
  },
  logo: {
    padding: 20,
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
    borderTopColor: 'black',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    height: 1,
  },
});

const Login = ({
  route,
  navigation,
  currentlySending,
  authMethods,
  nameOrganization,
  isAuthenticated,
  requestAuthInfo,
  setIsAuthenticated,
}) => {
  useEffect(() => {
    if (route?.params?.lti && route?.params?.redirect) {
      const { lti } = route.params;
      setToken(lti); // TODO: Use a cleaner way to login (ensure to logout previous user?..)
      setIsAuthenticated(true);
    } else if (isAuthenticated) navigation.navigate(screenName.ROOMS);
    else requestAuthInfo();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (route?.params?.lti && route?.params?.redirect) {
        const { redirect, redirectParams } = route.params;
        const screen = redirect.replace(getOrigin(), '').split('/').pop();
        const params = JSON.parse(redirectParams);
        navigation.navigate(screenName.ACTIVITY_SCREENS, { screen, params });
      } else navigation.navigate(screenName.ROOMS);
    }
  }, [isAuthenticated]);

  const getAuthMethods = useCallback(() => {
    const findIndex = (type) => authMethods.findIndex((el) => el.type === type);

    const methods = [];
    let i = -1;

    // Succession of if the ensure the same order no matter what!
    i = findIndex(LOCAL);
    if (i > -1) methods.push(<LoginLocal key={LOCAL} />); // also include divider!

    i = findIndex(CAS);
    if (i > -1) {
      methods.push(<View key="divider" style={styles.divider} />);
      methods.push(<LoginCAS key={CAS} method={authMethods[i]} />);
    }

    return methods;
  }, [authMethods]);

  return (
    <View style={styles.container}>
      <View style={styles.form}>
        <ActivityIndicator animating={currentlySending} size="large" style={styles.loader} />
        <View style={styles.formContainer}>
          <Text style={styles.title}>{nameOrganization}</Text>
          {getAuthMethods()}
        </View>
      </View>
    </View>
  );
};

Login.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
  currentlySending: PropTypes.bool.isRequired,
  authMethods: PropTypes.array.isRequired,
  nameOrganization: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  // Actions
  requestAuthInfo: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestAuthInfo: () => dispatch(organizationActions.requestAuthInfo()),
    setIsAuthenticated: (bool) => dispatch(userActions.setIsAuthenticated(bool)),
  };
};

const mapStateToProps = createStructuredSelector({
  currentlySending: appSelectors.makeSelectCurrentlySending([
    REQUEST_LOGIN_LOCAL,
    REQUEST_LOGIN_CAS,
  ]),
  authMethods: organizationSelectors.makeSelectAuthMethods(),
  nameOrganization: organizationSelectors.makeSelectOrganizationName(),
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
