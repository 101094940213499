import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Request API
  requestRoom: ['_id'],
  requestRoomDevices: ['_room'],

  // Set reducers
  setRoom: ['room'],
  setMessages: ['messages'],
  setOwnMessage: ['message'],
  setIncomingMessage: ['message'],
  setConnectedUsers: ['users'],
  setConnectedUser: ['user'],
  removeConnectedUser: ['user'],
  setDisplayRightMenu: ['bool'],
  setRoomDevices: ['devices'],

  resetRoom: [],
});

export { Types };
export default Creators;
