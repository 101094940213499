/* eslint-disable react/prop-types */
import { StyleSheet, Text, View, FlatList, TouchableOpacity } from 'react-native';
import React from 'react';

import Plans from './SettingAffichagePlan';
import FondVert from './SettingAffichageFondVert';
import Resolution from './SettingAffichageResolution';
import Scene from './SettingAffichageScene';

import ArrowIcon from '../../../assets/settings/icon-title-arrow.svg';

import { navigate } from '../../../helpers/navigation';
import { screenName } from '../../../constants/navigation';
import colors from '../../../constants/colors';

const styles = StyleSheet.create({
  global: {
    flex: 1,
    flexDirection: 'row',
  },
  buttonMenu: {
    flex: 1,
  },
  SettingsAffichage: {
    flex: 6,
    backgroundColor: 'white',
  },
  navButtons: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navButtonsSelected: {
    flex: 4,
    backgroundColor: colors.getMainPurple(),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  menuIcon: {
    margin: 8,
    marginLeft: 12,
    alignSelf: 'center',
  },
  menuText: {
    margin: 8,
    color: 'rgba(0, 0, 0, 0.4)',
  },
  menuSelectedText: {
    margin: 8,
    color: 'white',
  },
  isSelectedPlans: {
    marginRigth: 57,
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: colors.getBluePrussian(),
  },
  isSelected: {
    marginRigth: 74,
    marginLeft: 145,
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: 'colors.getBluePrussian()',
  },
  isNotSelected: {
    marginRigth: 74,
    marginLeft: 145,
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: 'colors.getBluePrussian()',
    opacity: 0.42,
  },
  isNotSelectedPlans: {
    marginRigth: 57,
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.24,
    color: 'colors.getBluePrussian()',
    opacity: 0.42,
  },
  rectangle: {
    height: 1,
    marginTop: 9,
    marginLeft: 145,
    backdropFilter: 'blur(10px)',
    backgroundColor: colors.getMainPurple(),
  },
  rectanglePlans: {
    height: 1,
    marginTop: 9,
    backdropFilter: 'blur(10px)',
    backgroundColor: colors.getMainPurple(),
  },
});

export default class SettingAffichage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: 'Plans',
    };
  }

  close = () => {
    return this.props.disableClose ? null : (
      <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 100 }}>
        <TouchableOpacity onPress={() => navigate(screenName.HOME)}>
          <Text style={{ fontSize: 20, fontWeight: '700', color: colors.getPurpleHeart() }}>
            Fermer
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.SettingsAffichage}>
        <View style={{ flex: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 100 }}>
            <Text style={{ fontSize: 35, fontWeight: '700', color: colors.getBluePrussian() }}>
              PARAMÈTRES
            </Text>
            <ArrowIcon style={{ margin: 10 }} />
            <Text style={{ fontSize: 25, fontWeight: '800', color: colors.getMountainMeadow() }}>
              Affichage
            </Text>
          </View>
          {this.close()}
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 30,
            marginLeft: 100,
          }}
        >
          <FlatList
            centerContent={true}
            numColumns={4}
            data={[
              { isSelected: true, key: 'Plans' },
              { isSelected: false, key: 'Fond vert' },
              { isSelected: false, key: 'Résolution' },
              { isSelected: false, key: 'Scènes' },
            ]}
            renderItem={({ item }) => this.renderMenu(item)}
          />
        </View>
        <View style={{ flex: 10, height: 1 }}>{this.changeStagteMenu()}</View>
      </View>
    );
  }

  renderMenu = (item) => {
    if (item.key === this.state.selectedMenu) {
      if (item.key === 'Plans') {
        return (
          <View>
            <Text
              style={styles.isSelectedPlans}
              onPress={() => this.setState({ selectedMenu: item.key })}
            >
              {item.key}
            </Text>
            <View style={styles.rectanglePlans}></View>
          </View>
        );
      }
      return (
        <View>
          <Text style={styles.isSelected} onPress={() => this.setState({ selectedMenu: item.key })}>
            {item.key}
          </Text>
          <View style={styles.rectangle}></View>
        </View>
      );
    }
    if (item.key === 'Plans') {
      return (
        <Text
          style={styles.isNotSelectedPlans}
          onPress={() => this.setState({ selectedMenu: item.key })}
        >
          {item.key}
        </Text>
      );
    }
    return (
      <Text style={styles.isNotSelected} onPress={() => this.setState({ selectedMenu: item.key })}>
        {item.key}
      </Text>
    );
  };

  changeStagteMenu = () => {
    if (this.state.selectedMenu === 'Plans') return <Plans />;
    if (this.state.selectedMenu === 'Fond vert') return <FondVert />;
    if (this.state.selectedMenu === 'Résolution') return <Resolution />;
    if (this.state.selectedMenu === 'Scènes') return <Scene />;
    return null;
  };
}
