import { createSelector } from 'reselect';

const roomSelector = (globalState) => globalState.get('room');
const messagesSelector = (state) => roomSelector(state).get('messages');
const usersSelector = (state) => roomSelector(state).get('users');
const displayRightMenuSelector = (state) => roomSelector(state).get('displayRightMenu');
const roomInfoSelector = (state) =>
  roomSelector(state).filterNot((val, key) =>
    ['messages', 'users', 'displayRightMenu'].includes(key),
  );

// Selectors
const makeSelectRoom = () => createSelector(roomInfoSelector, (state) => state.toJS());

const makeSelectMessages = () => createSelector(messagesSelector, (state) => state.toJS());

const makeSelectConnectedUsers = () => createSelector(usersSelector, (state) => state.toJS());

const makeSelectDisplayRightMenu = () => createSelector(displayRightMenuSelector, (state) => state);

export default {
  makeSelectRoom,
  makeSelectMessages,
  makeSelectConnectedUsers,
  makeSelectDisplayRightMenu,
};
