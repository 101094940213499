import { call, put, takeLatest } from 'redux-saga/effects';

import api from '../api/_index';
import errorActions from '../actions/error';
import appActions from '../actions/app';

import { Types as deviceTypes } from '../actions/device';

const { REQUEST_UPDATE_DEVICE } = deviceTypes;

function* requestUpdateDevice({ _id, body }) {
  try {
    yield put(appActions.addCurrentlySending(`${REQUEST_UPDATE_DEVICE}`));

    yield call(api.device.requestUpdateDevice, _id, body);
  } catch (error) {
    yield put(errorActions.handleError(error));
  } finally {
    yield put(appActions.removeCurrentlySending(`${REQUEST_UPDATE_DEVICE}`));
  }
}

function* watcherRequestUpdateDevice() {
  yield takeLatest(REQUEST_UPDATE_DEVICE, requestUpdateDevice);
}

export default [watcherRequestUpdateDevice()];
