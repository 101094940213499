import { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-native-gesture-handler';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import appActions from '../../App/actions/app';
import organizationSelectors from '../../App/selectors/organization';

const WithSocket = ({ organizationId, socketConnect, socketDisconnect }) => {
  useEffect(() => {
    if (organizationId !== undefined) socketConnect();

    // Cleanup effect
    return () => {
      if (organizationId !== undefined) socketDisconnect();
    };
  }, [organizationId]);

  return null;
};

WithSocket.propTypes = {
  organizationId: PropTypes.string,
  // Actions
  socketConnect: PropTypes.func.isRequired,
  socketDisconnect: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  organizationId: organizationSelectors.makeSelectOrganizationId(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    socketConnect: () => dispatch(appActions.socketConnect()),
    socketDisconnect: () => dispatch(appActions.socketDisconnect()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithSocket);
