import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Lottie from 'react-lottie-player';

import { sVideoControlRow } from '../../styles/fixed';

import deviceSelectors from '../../App/selectors/device';
import deviceActions from '../../App/actions/device';

import LiveButton from '../../assets/animations/readyToRecord.json';
import StopButton from '../../assets/animations/recording.json';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: sVideoControlRow.height,
    width: sVideoControlRow.height,
    cursor: 'default',
    overflow: 'hidden',
  },
});

const VideoControl = ({ deviceStatus, startLive, stopLive }) => {
  const onClickLiveControl = () => {
    if (deviceStatus.online) {
      if (deviceStatus.liveStartedAt) stopLive();
      else startLive();
    }
  };

  const getIconLiveControl = () => {
    if (!deviceStatus.online) return null;
    if (Platform.OS === 'web') {
      if (deviceStatus.liveStartedAt) {
        return (
          <Lottie
            animationData={StopButton}
            loop
            play
            style={{
              height: sVideoControlRow.height * 2,
              width: sVideoControlRow.height * 2,
              cursor: 'pointer',
            }}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        );
      }
      return (
        <Lottie
          animationData={LiveButton}
          loop
          play
          style={{
            height: sVideoControlRow.height * 2,
            width: sVideoControlRow.height * 2,
            cursor: 'pointer',
          }}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      );
    }
    if (deviceStatus.liveStartedAt) return <StopButton />;
    return <LiveButton />;
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onClickLiveControl}>{getIconLiveControl()}</TouchableOpacity>
    </View>
  );
};

VideoControl.propTypes = {
  deviceStatus: PropTypes.object.isRequired,
  startLive: PropTypes.func.isRequired,
  stopLive: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  deviceStatus: deviceSelectors.makeSelectDeviceStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    startLive: () => dispatch(deviceActions.startLive()),
    stopLive: () => dispatch(deviceActions.stopLive()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoControl);
