import { createSelector } from 'reselect';

const activitySelector = (globalState) => globalState.get('activity');
const availableRoomsSelector = (state) => activitySelector(state).get('availableRooms');
const activityInfoSelector = (state) => activitySelector(state).get('info');

const makeSelectAvailableRooms = () =>
  createSelector(availableRoomsSelector, (state) => state.toJS());

const makeSelectActivityInfo = () => createSelector(activityInfoSelector, (state) => state.toJS());

export default {
  makeSelectAvailableRooms,
  makeSelectActivityInfo,
};
