import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Datepicker, localeFr } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import './customStyles.css';

const CustomDatepicker = ({ now, datetimes, setDatetime, onClose }) => {
  const onWebInit = (event, instance) => {
    instance.setTempVal(datetimes); // Infinite loop without it !
  };

  const onWebTempChange = (event, instance) => {
    const tempValues = instance.getTempVal();

    if (tempValues[0].isSame(datetimes[0]) && tempValues[1] === null && datetimes[1] === null) {
      const newDatetime = [tempValues[0], tempValues[0].clone().add(1, 'h')];
      instance.setTempVal(newDatetime);
    }

    setDatetime(instance.getTempVal());
  };

  const WebDatepicker = () => (
    <Datepicker
      controls={['calendar', 'time']}
      select="range"
      touchUi={true}
      tagInput={true}
      stepMinute={15}
      locale={localeFr} // TODO: Dev a proper way to get the locale...
      min={now}
      value={datetimes}
      minRange={900000} // Minimum range (15 minutes)
      inputProps={{ inputStyle: 'outline' }}
      onInit={onWebInit}
      onTempChange={onWebTempChange} // Since we're using "range" for select props.
      onChange={onClose} // Shitty workaround ... onClose is always triggered.......
      moment={moment}
      returnFormat="moment"
    />
  );

  // TODO: Return datepicker depending on Platform
  return WebDatepicker();
};

CustomDatepicker.propTypes = {
  now: PropTypes.object.isRequired,
  datetimes: PropTypes.array.isRequired,
  setDatetime: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CustomDatepicker;
