import { StyleSheet, Text, View, Image, Platform } from 'react-native';
import React from 'react';

import IconRtmp from '../../../assets/modals/icon-rtmp.svg';
import colors from '../../../constants/colors';
import WebModalRtmp from '../modals/WebModalRtmp';

const Facebook = require('../../../assets/modals/icon-facebook.jpg');
const Twitch = require('../../../assets/modals/icon-twitch.jpg');
const Youtube = require('../../../assets/modals/icon-youtube.jpg');

const styles = StyleSheet.create({
  Streaming: {
    flex: 6,
    paddingHorizontal: 30,
    paddingTop: 24,
    paddingBottom: 20,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
    marginRight: 50,
  },
  TitleText: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.getBluePrussian(),
  },
  IconStream: {
    marginHorizontal: 'auto',
    marginTop: 24,
    marginBottom: 13,
    width: 40,
    height: 40,
  },
  TextIcon: {
    fontSize: 13,
    textAlign: 'center',
    color: colors.getBluePrussian(),
  },
  TextIconAdd: {
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    color: colors.getMainPurple(),
  },
  listContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
  },
});

export default class SettingPlatformesStreaming extends React.Component {
  data = [
    { id: 1, type: 'ajout', name: 'Ajouter', lock: false },
    { id: 2, type: 'facebook', name: 'Facebook', lock: false },
    { id: 3, type: 'youtube', name: 'Youtube', lock: false },
    { id: 4, type: 'twitch', name: 'Twitch Atrioom', lock: true },
    { id: 5, type: 'rtmp', name: 'RTMP Atrioom', lock: false },
  ];

  render() {
    return (
      <View style={styles.Streaming}>
        <Text style={styles.TitleText}>Mes plateformes de streaming</Text>
        <View style={styles.listContainer}>{this.data.map((el) => this.selectionList(el))}</View>
      </View>
    );
  }

  selectionList = (item) => {
    switch (item.type) {
      case 'ajout':
        return <WebModalRtmp key="ajout" />;
      case 'facebook':
        return (
          <View
            key="facebook"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            {this.getPicture(Facebook)}
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      case 'youtube':
        return (
          <View
            key="youtube"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            {this.getPicture(Youtube)}
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      case 'twitch':
        return (
          <View
            key="twitch"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            {this.getPicture(Twitch)}
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      case 'rtmp':
        return (
          <View
            key="rtmp"
            style={{
              width: 108,
              height: 108,
              margin: 15,
              borderRadius: 20,
              backgroundColor: 'white',
            }}
          >
            <IconRtmp style={styles.IconStream} />
            <Text style={styles.TextIcon}>{item.name}</Text>
          </View>
        );
      default:
        return null;
    }
  };

  getPicture = (type) => {
    if (Platform.OS === 'web') {
      return (
        <img
          src={type}
          style={{
            marginLeft: 34,
            marginRight: 34,
            marginTop: 24,
            marginBottom: 13,
            width: 40,
            height: 40,
          }}
        />
      );
    }
    return <Image source={type} style={styles.IconStream} />;
  };
}
