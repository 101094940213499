import { StyleSheet, View } from 'react-native';
import React from 'react';

import Messages from './Messages';
import Footer from './Footer';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    maxHeight: '100vh',
  },
});

const Chat = () => {
  return (
    <View style={styles.container}>
      <Messages />
      <Footer />
    </View>
  );
};

export default Chat;
