import React, { useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import colors from '../../constants/colors';
import SettingPage from '../SettingPage';
import SettingDevice from '../SettingPage/SettingView/SettingDevice';
import Devices from './devices';

import appSelectors from '../../App/selectors/app';
import deviceSelectors from '../../App/selectors/device';
import organizationSelectors from '../../App/selectors/organization';
import organizationAction, { Types } from '../../App/actions/organization';
import { getDomain } from '../../helpers/navigation';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.getMirage(),
    flexDirection: 'row',
  },
  body: {
    flex: 5,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: colors.getMainWhite(),
  },
  text: {
    color: colors.getMainWhite(),
  },
});

const PanelPage = ({ currentlySending, device, rooms, requestRooms, organizationId }) => {
  useEffect(() => {
    if (organizationId !== undefined && rooms.length === 0) requestRooms();
  }, [organizationId]);

  const getBody = () => {
    if (currentlySending) return <ActivityIndicator size="large" style={styles.loading} />;
    if (device._id === undefined) {
      // if devices not selected, display "Select a device"
      return (
        <View style={styles.loading}>
          <Text style={styles.text}>Sélectionner une caméra</Text>
        </View>
      );
    }
    if (!getDomain().includes('kalyzee')) {
      return <SettingDevice device={device} rooms={rooms} />;
    }
    return <SettingPage hideNavBarTop disableClose />;
  };

  return (
    <View style={styles.container}>
      <View style={styles.body}>{getBody()}</View>
      <Devices />
    </View>
  );
};

PanelPage.propTypes = {
  currentlySending: PropTypes.bool.isRequired,
  device: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  requestRooms: PropTypes.func.isRequired,
  organizationId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  currentlySending: appSelectors.makeSelectCurrentlySending([Types.REQUEST_DEVICES]),
  organizationId: organizationSelectors.makeSelectOrganizationId(),
  rooms: organizationSelectors.makeSelectRooms(),
  device: deviceSelectors.makeSelectDeviceInfo(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestRooms: () => dispatch(organizationAction.requestRooms()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelPage);
