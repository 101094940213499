import { StyleSheet, View } from 'react-native';
import React from 'react';

import FirstSceneActive from '../../../assets/blue/icon-first-plan-active.svg';
import SecondScene from '../../../assets/blue/icon-second-plan.svg';
import ThirdScene from '../../../assets/blue/icon-third-plan.svg';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(37, 49, 68, 0.25)',
    flex: 8,
    flexDirection: 'column',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
  },
});

const FIRST = 'first';
const SECOND = 'second';
const THIRD = 'third';

const MenuPlan = () => {
  return (
    <View style={styles.container}>
      <FirstSceneActive />
      <SecondScene />
      <ThirdScene />
    </View>
  );
};

export default MenuPlan;
