import { createRef } from 'react';
import platformHelpers from './platform';

import { DOMAIN_BY_ENV } from '../constants/global';

export const isReadyRef = createRef();

export const navigationRef = createRef();

export const navigate = (name, params) => {
  // eslint-disable-next-line no-unused-expressions
  if (isReadyRef.current && navigationRef.current) navigationRef.current?.navigate(name, params);
};

export const getDomain = () => platformHelpers.getDomain();

export const getOrigin = () => platformHelpers.getOrigin();

export const isAdminDomain = () => {
  const domain = getDomain();
  const appDomain = `${DOMAIN_BY_ENV[process.env.ENVIRONMENT]}`;
  return domain.replace(`${appDomain}`, '').length === 0;
};

export default {
  isReadyRef,
  navigationRef,
  navigate,
  getDomain,
  isAdminDomain,
};
