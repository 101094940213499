import React from 'react';
import { Image, Platform } from 'react-native';
import PropTypes from 'prop-types';

import { WEB_PLATFORM } from '../../constants/global';

const CustomImage = ({ image, style, styleWeb }) => {
  const getImage = () => {
    if (Platform.OS === WEB_PLATFORM) return <img src={image} style={styleWeb} />;
    return <Image source={image} style={style} />;
  };

  return getImage();
};

CustomImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  style: PropTypes.object,
  styleWeb: PropTypes.object,
};

CustomImage.defaultProps = {
  style: {},
  styleWeb: {},
};

export default CustomImage;
