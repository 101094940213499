import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { createStackNavigator } from '@react-navigation/stack';

import userSelectors from '../../App/selectors/user';

import { isAdminDomain } from '../../helpers/navigation';

import { screenName } from '../../constants/navigation';

import HomePage from '../../pages/HomePage';
import OrganizationPage from '../../pages/OrganizationPage';
import RoomPage from '../../pages/RoomPage';
import LogoutPage from '../../pages/LogoutPage';
import LoginPage from '../../pages/LoginPage';
import SettingPage from '../../pages/SettingPage';
import SchedulePage from '../../pages/SchedulePage';
import ActivityPage from '../../pages/ActivityPage';
import PanelPage from '../../pages/PanelPage';

export const Stack = createStackNavigator();
const ActivityStack = createStackNavigator();

const ActivityScreens = () => {
  return (
    <ActivityStack.Navigator screenOptions={{ headerShown: false }}>
      <ActivityStack.Screen name={screenName.SCHEDULE} component={SchedulePage} />
      <ActivityStack.Screen name={screenName.ACTIVITY} component={ActivityPage} />
    </ActivityStack.Navigator>
  );
};

const AuthAwareStack = ({ isAuthenticated }) => {
  const publicScreens = [
    <Stack.Screen key={screenName.LOGIN} name={screenName.LOGIN} component={LoginPage} />,
  ];

  const privateScreens = [
    <Stack.Screen key={screenName.LOGIN} name={screenName.LOGIN} component={LoginPage} />,
    <Stack.Screen key={screenName.ROOMS} name={screenName.ROOMS} component={RoomPage} />,
    <Stack.Screen key={screenName.HOME} name={screenName.HOME} component={HomePage} />,
    <Stack.Screen key={screenName.SETTINGS} name={screenName.SETTINGS} component={SettingPage} />,
    <Stack.Screen key={screenName.LOGOUT} name={screenName.LOGOUT} component={LogoutPage} />,
    <Stack.Screen
      key={screenName.ACTIVITY_SCREENS}
      name={screenName.ACTIVITY_SCREENS}
      component={ActivityScreens}
    />,
    <Stack.Screen key={screenName.PANEL} name={screenName.PANEL} component={PanelPage} />,
  ];

  const adminScreens = [
    <Stack.Screen
      key={screenName.ORGANIZATIONS}
      name={screenName.ORGANIZATIONS}
      component={OrganizationPage}
    />,
    ...privateScreens,
  ];

  const getScreen = useCallback(() => {
    if (isAuthenticated) {
      if (isAdminDomain()) return adminScreens;
      return privateScreens;
    }
    return publicScreens;
  }, [isAuthenticated]);

  return <Stack.Navigator screenOptions={{ headerShown: false }}>{getScreen()}</Stack.Navigator>;
};

AuthAwareStack.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.makeSelectIsAuthenticated(),
});

export default connect(mapStateToProps, null)(AuthAwareStack);
